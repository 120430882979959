<template>
  <div id="fantasy-bg">
    <div id="msg-area"></div>
    <header v-if="!isMindMapPage">
      <div class="HeadDiv between">
        <div class="logoDiv oneLine" @click="routerskip('/')" v-if="ActiveShowCtrl === 0">
          <img class="logoImg" src="./assets/img/logo/Taoyuan.png" alt="">
          <div class="LogoText div-centerY2">
            <b>{{ $t("header.digitalLearningOffice", { region: region_name }) }}<br>
              {{ $t("header.authorizationPlatform") }}</b>
          </div>
        </div>
        <div class="logoDiv oneLine" @click="routerskip('/Activity_Info')" v-if="ActiveShowCtrl === 1">
          <img class="logoImg2" src="./assets/img/logo/tmd_newlogo.png" alt="">
        </div>
        <div v-if="ActiveShowCtrl !== 2">
          <div class="oneLine"  v-if="isPC">
            <a :href=region_url class="chooseBtn" target="_blank" v-if="ActiveShowCtrl === 0">{{
              $t("header.cityInformation", { region: region_name }) }}</a>
            <a href="https://www.habook.com/zh-tw/cloud.php?act=view&id=18/" class="chooseBtn" target="_blank">{{
              $t("header.softwareIntroduction") }}</a>
            <a href="https://www.habook.com/zh-tw/faq.php?act=view&id=236" class="chooseBtn" target="_blank">{{
              $t("header.learningResources") }}</a>
            <a href="https://www.habook.com/zh-tw/news.php?act=list&cid=1" class="chooseBtn" target="_blank">{{
              $t("header.latestNews") }}</a>

            <!--聯絡我們，測試站與活動好小子站不同-->
            <a v-if="ActiveShowCtrl===0" href="https://www.habook.com/zh-tw/contact_us.php" class="chooseBtn" target="_blank">{{
              $t("header.contactUs") }}</a>
            <a v-if="ActiveShowCtrl===1" href="https://line.me/R/ti/p/@845nycdd?from=page&liff.referrer=https%3A%2F%2Fwww.habook.com%2F&accountId=845nycdd&openerPlatform=native&openerKey=talkroom%3Amessage" class="chooseBtn" target="_blank">
              Line客服</a>

            <div class="chooseBtn" v-show="!isLogin" @click="gologin"><el-icon class="icon">
                <Avatar />
              </el-icon>{{ $t("header.login") }}</div>
            <div class="chooseBtn" v-show="isLogin" v-if="ActiveShowCtrl !==1" @click="routerskip('/AccountInfo')"><el-icon class="icon">
                <Avatar />
              </el-icon>{{ open_name }}({{tmdid}})
            </div>
            <div class="chooseBtn noClick" v-show="isLogin" v-else ><el-icon class="icon"><!--如果是活動，就不能連到授權頁面-->
                <Avatar />
              </el-icon>{{ open_name }}({{tmdid}})
            </div>
            <div class="chooseBtn" v-show="isLogin" @click="logOut">登出</div>
            <!-- <div class="chooseBtn ">
              <el-icon><LocationFilled /></el-icon>
              <el-cascader v-model="languageValue" :options="options" @change="languageChange" class="languageBtn"/>
            </div>         -->
          </div>
          <div v-else class="phoneDIV">
            <el-icon class="titleIcon" @click="MenuFun">
              <!-- <MoreFilled /> -->
              <Fold />
            </el-icon>         
          </div>
        </div>
      </div>
      <svg :width="waveWidth" :height="waveheight" class="svgWave"><!--波浪繪製-->
        <path :d="wavePath" fill="#CADDE3"></path>
      </svg>
    </header>
    <body>
      <div v-if="menuOpen" class="menuArea">
        <div class="list">
          <a :href=region_url class="chooseBtn" target="_blank" v-if="ActiveShowCtrl === 0">{{
            $t("header.cityInformation", { region: region_name }) }}</a>
          <a href="https://www.habook.com/zh-tw/cloud.php?act=view&id=18/" class="chooseBtn" target="_blank">{{
            $t("header.softwareIntroduction") }}</a>
          <a href="https://www.habook.com/zh-tw/faq.php?act=view&id=236" class="chooseBtn" target="_blank">{{
            $t("header.learningResources") }}</a>
          <a href="https://www.habook.com/zh-tw/news.php?act=list&cid=1" class="chooseBtn" target="_blank">{{
            $t("header.latestNews") }}</a>

          <!--聯絡我們，測試站與活動好小子站不同-->
          <a v-if="ActiveShowCtrl===0" href="https://www.habook.com/zh-tw/contact_us.php" class="chooseBtn" target="_blank">{{
            $t("header.contactUs") }}</a>
          <a v-if="ActiveShowCtrl===1" href="https://line.me/R/ti/p/@845nycdd?from=page&liff.referrer=https%3A%2F%2Fwww.habook.com%2F&accountId=845nycdd&openerPlatform=native&openerKey=talkroom%3Amessage" class="chooseBtn" target="_blank">
            Line客服</a>

          <div class="chooseBtn" v-show="!isLogin" @click="gologin"><el-icon class="icon">
              <Avatar />
            </el-icon>{{ $t("header.login") }}</div>
          <div class="line"></div>
          <div class="chooseBtn" v-show="isLogin" v-if="ActiveShowCtrl !==1" @click="routerskip('/AccountInfo')"><el-icon class="icon">
              <Avatar />
            </el-icon>{{ open_name }}({{tmdid}})
          </div>
          <div class="chooseBtn noClick" v-show="isLogin" v-else ><el-icon class="icon"><!--如果是活動，就不能連到授權頁面-->
              <Avatar />
            </el-icon>{{ open_name }}({{tmdid}})
          </div>
          <div class="chooseBtn" v-show="isLogin" @click="logOut">登出</div>
        </div>
      </div>
      <router-view :class="{'default-padding':!isMindMapPage}" :blobEventDataJson="blobEventDataJson"></router-view>
      <p class="version">{{ store.version }}</p>
    </body>
    <!-- <p class="version">{{ store.version }}</p> -->
    <footer v-if="!ActiveShowCtrl&&!isMindMapPage">
      <div class="copyright">
        Copyright © HABOOK Group. All rights reserved.
      </div>
    </footer>
  </div>
</template>

<script setup>
import Message from "./components/Message.vue";
//import Navbar from "./components/Navbar.vue";
import { createApp, provide, onMounted, ref, computed, watch, onBeforeMount,onUnmounted, reactive } from "vue";
import { useMainStore } from "./stores/mainStore";
import { useRouter, useRoute } from 'vue-router';
import profile from "./api/profile";
import jwtDecode from 'jwt-decode';
import { post } from "@/api/http";
import { Base64 } from 'js-base64';
import api from "@/api";
//import { ElMessageBox } from 'element-plus';//ElMessageBox, 


const waveWidth = 3000;
const waveheight = 50; // 容器的高度
const waveFrequency = 50; // 值越大波浪越多
const waveAmplitude = 20; // 值越大波浪越高
// const languageValue = 'zh-TW';
// const options = [{ value: 'zh-TW', label: 'TW' }, { value: 'en-US', label: 'EN' }, { value: 'zh-CN', label: 'CN' }];
const routers = useRouter();
const route = useRoute();

const store = useMainStore();
const isLogin = ref(false);
let tmdid = ref("")
const open_name = ref("");
var serverLocation = "global";
var clientID = profile.clientID[serverLocation];
var coreAPIUrl = profile.apiUrl[serverLocation];
let region_name = ref("桃園市");
let region_url = ref("https://asset.tyc.edu.tw/");
let notitle = ['Binding_CheckAccount', 'Binding_Choose','Activity_CheckLogin'];//不顯示開頭
let ActivityPageList = ['HomePage_Activity', 'Activity_List', 'Activity_Info', 'Activity_Data','goodkid','Activity_InfoVer2'];//控制顯示的
let ActiveShowCtrl = ref(1);//0統購 1活動  2頁首頁尾不顯示
let isPC = ref(true);//判斷是否是PC
let menuOpen = ref(false);//手機板模式下，判斷選單是否打開
let blobEventDataJson = reactive("");//當前活動資訊
let blobEventData = reactive({});//當前活動資訊

// import { useGlobalMethods } from '@/utils/useGlobalMethods';
// const { afterLoginRouter } = useGlobalMethods();

// 使用 onMounted 鉤子
onMounted(async () => {
  document.dispatchEvent(new Event('render-event'));
  setLoginInfo();
  // console.log(open_name.value); 
  // if(ActiveShowCtrl.value===1)  document.title = '全國閱讀好小子學藝競賽活動' ;
  // else document.title = 'HiTeach 授權與活動平台';
  isMobileBrowser();
  window.addEventListener('resize', isMobileBrowser);
});
onUnmounted(() => {
  window.removeEventListener('resize', isMobileBrowser);
});


let wavePath = computed(() => {
  //const { waveWidth, waveheight, waveAmplitude, waveFrequency } = this;
  const waveLength = waveWidth / waveFrequency;
  let path = `M 0 ${waveheight}`;

  for (let i = 0; i <= waveFrequency; i++) {
    const x = i * waveLength;
    const y = i % 2 === 0 ? (waveheight / 2) - waveAmplitude : (waveheight / 2) + waveAmplitude;
    path += ` Q ${x - waveLength / 2} ${y} ${x} ${waveheight / 2}`;
  }
  path += ` L ${waveWidth} 0 L 0 0 Z`;
  return path;

});


let isMindMapPage = computed(() => {
  return route.name === 'MindMap'||route.name === 'MindMapHome';
});

function MenuFun(){
  menuOpen.value = menuOpen.value ? false : true;
}

//常用:mount彈出信息組件
const mountMsg = (text) => {
  if (store.mountedMsg) {
    store.mountedMsg.unmount(); //之前mounted卸除
  }
  const child = createApp(Message, { text: text });
  store.setMountedMsg(child);

  child.mount("#msg-area");
};
provide("mountMsg", mountMsg);


const updateUserInfo = () => {
  setLoginInfo()
};
provide("updateUserInfo", updateUserInfo);

const currentUrl = window.location.href;
if (!currentUrl.includes('/MindMap')&&!currentUrl.includes('/mindmaphome')) {
  // 監聽路由變化並刷新數據
  watch(route, () => {
    setLoginInfo();
  });
}
async function setLoginInfo() {  
  if (sessionStorage.getItem("open_name")) {
    isLogin.value = true;
    open_name.value = sessionStorage.getItem("open_name");

    let t_data = jwtDecode(sessionStorage.getItem("id_token"));
    tmdid.value = t_data.sub;

    // 以後可能需要依照使用者學校代縣市
    // region_name.value = sessionStorage.getItem("region_name");
    // const countyData = region_url_data.find(item => item.region_name === region_name.value);
    //   if (countyData) {
    //     region_url.value = countyData.url;
    //   }    
  }
  // 依照不同活動網址登入方式有變化 先判斷目前是測試站還是正式站
    const activeArr = [
      { pathname: "Activity_InfoVer2", testId: "7381165f-6def-44e8-a043-07b057223e77", onlineId: "7381165f-6def-44e8-a043-07b057223e77" },
      //{ pathname: "Activity_InfoVer2", testId: "4e224d75-2d9a-49fc-9719-c63b6f5b9e9c", onlineId: "4e224d75-2d9a-49fc-9719-c63b6f5b9e9c" },
      //{ pathname: "Activity_InfoVer2", testId: "ac3aa259-80ee-45e2-bfaf-16cb71759cab", onlineId: "ac3aa259-80ee-45e2-bfaf-16cb71759cab" },
      { pathname: "Activity_InfoVer3", testId: "", onlineId: "" },
    ]
    let testWebList = ['localhost', 'community-test'];//測試站的網站名單
    let websitKeyWord = window.location.host;
    let isTest = testWebList.some(keyword => websitKeyWord.includes(keyword));
    let data = { jointEventId: "" };
    let actItem = activeArr.find(item => window.location.pathname.includes(item.pathname))
    if (actItem) {
      if (isTest) { // 測試站             
        data.jointEventId = actItem.testId
      }
      else { // RC或正式站      
        data.jointEventId = actItem.onlineId
      }
      // 取blob
      let res = await api.main.getEventData(data);
      if (res.errCode === "") {
        // 先將資料存起來 等按登入時使用
        blobEventDataJson = res.jointEvent
        blobEventData = JSON.parse(res.jointEvent)
      }
      else {
        console.error(res.error);
      }
    }     
  //判斷網址決定header顯示
  if (ActivityPageList.some(keyword => window.location.href.includes(keyword))) ActiveShowCtrl.value = 1;//活動
  else if (notitle.some(keyword => window.location.href.includes(keyword))) ActiveShowCtrl.value = 2;//不顯示上下
  else ActiveShowCtrl.value = 0;//統購
}

//切換語系
// import { useI18n } from "vue-i18n";
// const { locale } = useI18n();
// function languageChange(value) {
//   locale.value = value.toString();
// }

function routerskip(val) {
  routers.push(val);
}
function gologin() {
  if (ActiveShowCtrl.value === 1) {//活動
    if(location.pathname === "/Activity_Info"){
      sessionStorage.setItem("info", "2");
    }else if(location.pathname === "/goodkid"){//特定活動
      sessionStorage.setItem("info", "3");
    }else if(location.pathname === "/Activity_InfoVer2"){//2025版
      sessionStorage.setItem("info", "Activity_InfoVer2");
    }
    // 
    if (blobEventData.loginType.includes("tmdId")) {
        // 皆可登入或是僅可用tmdid登入
        routers.push({ path: '/Activity_CheckLogin', query: { eid: encodeURIComponent(Base64.encode(blobEventData.id))} });     
      } else {
        // 其他狀況預設用 eduid登入
        let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
        let data = {
          client_id: profile.clientID["global"],
          op_redirecturi: "https://" + urlhost + "/Binding_CheckAccount"
        }
        let datastr = encodeURIComponent(Base64.encode(JSON.stringify(data)));
        // let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
        // let url = "https://oidc.tanet.edu.tw/onesteplogin?Auth_Request_RedirectUri=https://account.teammodel.net/quickopencode/educloudtw/" + profile.clientID["global"] + "?op_redirecturi=" + Base64.encode("https://" + urlhost + "/Binding_CheckAccount") + "&Auth_Request_State=educloudtw&Auth_Request_Response_Type=code&Auth_Request_Client_ID=c58722f58275a8c81fcc5ed6b94f3e7b&Auth_Request_Nonce=aaaaa&Auth_Request_Scope=openid+email+profile+eduinfo&local=true";
        let url = "https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=https://account.teammodel.net/&scope=openid+email+profile+eduinfo&state=" + datastr + "&nonce=aaaaa";
        location.href = url;
      }

  }else {
      sessionStorage.setItem("info", "1");
  }     
  // let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
  // let data = {
  //    client_id: profile.clientID["global"],
  //    op_redirecturi: "https://" + urlhost + "/Binding_CheckAccount"
  // }
  // let datastr = encodeURIComponent(Base64.encode(JSON.stringify(data)));
  
  // //"https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=https://account.teammodel.net/&scope=openid+email+profile+eduinfo&state=[編碼結果]&nonce=aaaaa"
  // // let url = "https://oidc.tanet.edu.tw/onesteplogin?Auth_Request_RedirectUri=https://account.teammodel.net/quickopencode/educloudtw/" 
  // //+ profile.clientID["global"] + "?op_redirecturi=" + Base64.encode("https://" + urlhost + "/Binding_CheckAccount") + "&Auth_Request_State=educloudtw&Auth_Request_Response_Type=code&Auth_Request_Client_ID=c58722f58275a8c81fcc5ed6b94f3e7b&Auth_Request_Nonce=aaaaa&Auth_Request_Scope=openid+email+profile+eduinfo&local=true";
  // let url = "https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=https://account.teammodel.net/&scope=openid+email+profile+eduinfo&state="+datastr+"&nonce=aaaaa";
  // location.href = url;
}
// function getTokenUnlogin() {
//   api.main.getTokenUnlogin().then((res) => {
//     //將access_token存本地, http 攔截器使用
//     sessionStorage.setItem("access_token", res.access_token);
//   });
// }


async function logOut() {  // 登出
  let data = {
    client_id: clientID,
    id_token: sessionStorage.getItem("id_token")
  };
  try {
    //debugger
    await post(coreAPIUrl + "/oauth2/logout", data).then(
      async (res) => {
        if (res.error) {
          console.error(res.error)
        } else {           
          sessionStorage.removeItem("access_token");
          sessionStorage.removeItem("id_token");
          sessionStorage.removeItem("open_name");
          sessionStorage.removeItem("school_name");
          sessionStorage.removeItem("region_name");
          sessionStorage.removeItem("open_id");
          // sessionStorage.removeItem("info");
          // getTokenUnlogin();
          open_name.value = "";
          isLogin.value = false;
          if (sessionStorage.getItem("edu_id_token")) {
            let post_logout_redirect_uri = location.href;
            let id_token_hint = sessionStorage.getItem("edu_id_token");
            location.href = "https://oidc.tanet.edu.tw/oidc/v1/userlogout?post_logout_redirect_uri=" + post_logout_redirect_uri + "&state=" + Date.now().toString() + "&client_id=" + clientID + "&id_token_hint=" + id_token_hint;
          }else{
            // 移除 Query String
            history.replaceState(null, "", window.location.pathname);
            
            // 重新整理頁面
            window.location.reload();            
          }
          // let dataedu = {
          //   nonce:0,
          //   post_logout_redirect_uri: location.href,
          //   state: Date.now().toString(),
          //   client_id: clientID,
          //   id_token_hint: sessionStorage.getItem("edu_id_token")
          // };
          //  await post(coreAPIUrl + "/oauth2/login", dataedu).then(
          //    (resedu) => {
          //     console.log(JSON.stringify(resedu))
          //     afterLoginRouter();
          //    })

          // ElMessageBox.alert(
          //   '請使用者先在 <a href="https://oidc.tanet.edu.tw/auth-logout" target="_blank" style="color: blue; text-decoration: underline;">此頁</a> 進行登出，再點OK。', 
          //   '通知', 
          //   {
          //     confirmButtonText: 'OK',
          //     dangerouslyUseHTMLString: true,
          //     callback: (action) => {
          //       if (action === 'confirm') {
          //         afterLoginRouter();
          //       }
          //     }
          //   }
          // );          
          //afterLoginRouter("logout");          

        }
      },
      (err) => {
        console.error(err)
      }
    );
  } catch (e) {
    console.error(e)
  }

  //sessionStorage.removeItem("id_token");
  // routers.push("/").then(() => {
    //window.location.reload();
  // });
}


const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = this;
    if (tid) {
      clearTimeout(tid);
    }
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};
onBeforeMount(() => {
  //先以未登入情況，取得access_token 於本地，先暫定以國際站做存取
  // getTokenUnlogin();
});

const ResizeObserverOriginal = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends ResizeObserverOriginal {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

function isMobileBrowser() {//判斷是否是電腦
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.browser|up\.link|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|acer|aos|airn|alav|alco|amoi|anex|anyw|aptu|arch|argo|aste|asus|attw|audi|avam|beck|bell|bllm|bilb|bird|blaz|brew|bumb|bwnu|c55|capi|ccwa|cdm|cell|chtm|cldc|cmd|comp|cond|craw|dait|dll|dang|dbte|dc-s|devi|dica|dmob|doc|dop|ds12|elae|eml2|emul|eric|erk0|esl8|ezos|ezwa|ezze|fetc|fly-|g1 u|g560|gene|gf-5|g-mo|go\.w|good|grad|grun|haie|hcit|hd-m|hd-p|hdt|hei-|hipt|hita|hp i|hip|hsc|htc-|htc |htca|htcg|htcp|htcs|htct|hua|htc|tp|inno|ipaq|iris|jatv|jbro|jemu|jigs|kddi|keji|kgt|klon|kpt|kwc|kyoc|lgg|lgl|libw|lynx|m1-w|m3ga|m50|mate|maxt|mc01|mcr|merc|meri|mio8|moa|mots|mmef|mo01|mo02|mobt|mod|movt|mt50|mtp1|mwpb|mywa|n100|n200|n300|n500|n700|necm|neon|netf|neon|netwf|nok6|nzph|o2im|opti|oran|owg1|p800|pana|pdxd|pg13|pgc|phil|pire|play|pluc|pn2|pock|port|pose|prox|psio|ptg|qaa|qca|qci|qtek|r380|r600|raks|rim9|rove|rozo|s55|sage|sam|sammm|sans|sony|sap|sch-|scoo|sdk|sec-|sec0|se47|secn|seri|se0|sght|shar|siem|sk-0|sl45|smar|sony|soft|spot|sph-|spv-|syo1|symb|t218|t600|t610|t618|tagt|talk|tcl-|tdg-|telm|teli|tim-|t-mo|topl|tos|tsh|ts70|tx-|up\.b|upsi|utst|v400|v750|veri|vird|v40|vm40|vod|vulc|vx52|vx53|vx60|vx61|vx70|vx80|vx81|vx83|vx85|vx98|w3c-|webc|whit|win|winc|wnw|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  isPC.value = !check;
  sessionStorage.setItem("isPC", !check);
}


</script>

<style lang="less">
@import "./assets/tailwind.css";

@fontColor :#424f55; //文字：深藍
@fontColor-light :#8da3a0; //文字：中藍
@stressColor :#f59f56; //強調色：橘色
@background-yellow: #F6EDE4; //背景：淡黃
@background-blue: #CADDE3; //背景：淡藍(header顏色)
@background-gray: #eff4f7; //背景：淡灰(footer顏色)
@other-blue: #70a6b2; //其他輔助色：水藍

@fontColor-gray1: #909399;
@fontColor-gray2: #b1b3b8;

.stressColor
{
  color: @stressColor;
}

.lightColor
{
  color: @fontColor-light;
}

.otheBlueColor
{
  color: @other-blue;
}

.whiteColor
{
  color: white;
}

.gray1Color
{
  color: @fontColor-gray1;
}

#fantasy-bg
{
  //@apply min-h-screen bg-cyan-100 relative;
  // @apply min-h-screen bg-gradient-to-br from-gray-100 via-gray-200 to-gray-100;
  color: @fontColor; //全域文字顏色
  font-size: 14px; //全域文字大小
  @apply min-h-screen relative;
  // background-image: url("/src/assets/img/login_bg.jpg");
  overflow-x: hidden;
}
.default-padding{
  padding: 10px 50px 20px 50px;
}
#msg-area {
  position: relative;
}

.version
{
  @apply text-blue-400 text-sm absolute right-1 bottom-0;
}

//scrollbar style
::-webkit-scrollbar
{
  /* width */
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb
{
  /* Handle */
  background: rgba(0, 0, 0, 0.1);
  /* 使用淡化的顏色 */
  border-radius: 5px;
  border: 1px solid white;
  /* 添加邊框，創建間隙 */
}

::-webkit-scrollbar-thumb:hover
{
  /* Handle on hover */
  background: rgba(0, 0, 0, 0.2);
  /* 使用稍深的顏色 */
}

.oneLine
{
  /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
}

.between
{
  /*子元素分左右兩邊*/
  display: flex;
  justify-content: space-between;
}

.div-centerY2
{
  //垂直置中2
  margin-top: auto;
  margin-bottom: auto;
}

header
{
  .svgWave{
    z-index: 999;
    position: relative;
  }
  .HeadDiv
  {
    position: relative;
    width: 100%;
    background-color: @background-blue;
    padding: 10px 20px 0px 20px;
    overflow: hidden;
    z-index: 999;
    .logoDiv
    {
      cursor: pointer; //滑鼠變成小手

      .logoImg
      {
        height: 40px;
      }

      .logoImg2
      {
        max-height: 50px;
        margin-left: 10px;
        max-width: unset !important;
      }

      .LogoText
      {
        margin-left: 10px;
        line-height: 15px;
      }
    }

    .chooseBtn
    {
      display: flex;
      align-items: center;
      padding-left: 30px;
      font-size: 16px;
      color: @fontColor;
      cursor: pointer; //滑鼠變成小手
      &.noClick{
        cursor:unset;
      }

      .icon
      {
        margin: 0 5px;
      }
    }

    .languageBtn
    {
      .el-input__wrapper
      {
        background-color: unset;
        box-shadow: unset;
      }

      .el-input__inner
      {
        width: 30px;
      }
    }
    .phoneDIV{
      .titleIcon{
        font-size: 25px;
        margin: 10px 10px 0 0;
      }
    }
  }
}
body{
  .menuArea{
    padding-top: 120px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // height: 100%;
    z-index: 100;
    background: white;
    display: flex;
    justify-content: center;
    .list{
      display: flex;
      flex-direction: column;
      align-items: center; 
      .line{
        margin-top: 20px;
        width: 100%;
        height: 0;
        border-top: 2px dashed var(--el-border-color);
        // border-top: 1px solid var(--el-border-color);
      }
      .chooseBtn{
        font-size: 25px;
        margin-top: 20px;
      }
    }
  }
}

footer
{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px !important;
  background-color: @background-gray;
  text-align: center;
  padding: 10px 0;
  color: @fontColor-light;
  font-size: 12px;

  .copyright
  {}
}
</style>
