<template>
  <div class="home-page">    
    <!-- <div class="between backDiv"> --預留！不要刪掉！！--
      <div>
        <span class="cursorPointer" @click="goToUrl('/HomePage_Activity')" >活動主頁 </span> / 
        <span class="cursorPointer" @click="goToUrl('/Activity_List')">活動列表</span> / 
        <span>活動資訊</span>
      </div>
      <div @click="goToUrl('/Activity_List')" class="cursorPointer PageBack oneLine">
        <div class="div-centerY2  backTxt">
          返回
        </div>
        <img class="backImg" src="../../assets/img/bean/bean_02.png" alt="">
      </div>
    </div> -->
    <div class="div-centerY ActiveAllInfo ">
      <div v-if="isPC" class="ActiveTitle">全國閱讀好小子學藝競賽活動</div>
      <div v-else class="ActiveTitle PC div-center">全國閱讀好小子<br>學藝競賽活動</div>
      <div class="ActiveInfoDiv oneLine">
        <div class="ActivePic">
          <img class="pic" src="../../assets/img/bean/bean_05.png" alt="">
        </div>
        <div class="titleContentArea">
          <!-- <div class="ActiveTitle">全國閱讀好小子學藝競賽活動</div> -->
          <div class="otherInfoArea" >
            <p>
              本活動參考了PISA、PIRLS等級的素養文本，並結合專家設計命題，呈現多元且有趣的閱讀素養題型，激發學生的學習興趣。<br>
              <br>
              透過HiTeach教學軟體，可以在課堂上更快速、高效地進行閱讀測驗，讓師生親身體驗科技融入教學的優勢，同時提升生生用平板的應用成效。<br>
              <br>
              歡迎各班級踴躍參與，共同探索學習新體驗！
              <br>
            </p>
            <div class="Replenish">*本活動數據將不會作為研究用途</div>
          </div>  
        </div>
      </div> 
    </div>
    <div class="oneLine div-center">
      <div :class="['joinButtonArea', !isPC &&'div-center']" >
        <div :class="['joinButton', 'downLoad', 'load', 'whiteColor', 'div-center', isPC && 'PC']"  >
          <a href="https://teammodelblob.teammodel.net/event/goodkid/2024%E5%85%A8%E5%9C%8B%E9%96%B1%E8%AE%80%E5%A5%BD%E5%B0%8F%E5%AD%90%E5%AD%B8%E8%97%9D%E7%AB%B6%E8%B3%BD%E6%B4%BB%E5%8B%95%E8%A8%88%E7%95%AB.pdf?sv=2023-01-03&st=2024-08-28T08%3A06%3A49Z&se=2024-11-10T15%3A59%3A00Z&sr=b&sp=r&sig=1iQNdxqfgWZxn5C30%2FLYAkmpaGK4xUD%2FZkyZXGYM1YQ%3D" target="_blank">
            <div class="txt div-centerY YUnset" style="color:white;">
              活動簡章下載
              <el-icon><ArrowRightBold /></el-icon>
            </div> 
          </a>
        </div>
      </div>
      <div class=" joinButtonArea"  @click="BtnFunction('join')" >        
        <div :class="['joinButton', 'whiteColor', 'div-center', ((ActiveStart && PageStatus===0 )? 'onClick' :''), 'mar-right', isPC && 'PC']"  >
          <div class="txt div-centerY YUnset">
            我要報名
            <el-icon><ArrowRightBold /></el-icon>
          </div> 
        </div>
      </div>
      <div :class="['joinButtonArea', !isPC &&'div-center']" v-show="isReviewer" >
        <div :class="['joinButton', 'downLoad', 'load', 'whiteColor', 'div-center', isPC && 'PC']"  >
          <a href='javascript:void(0)' @click="gotoTeammodel('%2Fhome%2FhtExamMark')">
            <div class="txt div-centerY YUnset" style="color:white;">
              評審閱卷
              <el-icon><ArrowRightBold /></el-icon>
            </div> 
          </a>
        </div>
      </div>
    </div>
    
    <div class="div-center">
      <div class="cotentDiv oneLine">
        <div :class="isPC ? 'tableDiv class left':'tableDiv left'" v-if="isJoin &&(classListCtrl || PageStatus!==0)">
          <div class="between titleDiv">
            <div class="title oneLine div-centerY YUnset">
              <img class="iconPic" src="../../assets/img/other_books.png" alt=""> 
              已報名：
            </div>
            <div class="picButton  "  @click="BtnFunction('edit')" v-if="PageStatus ===1">
              <img class="buttonImg" src="../../assets/img/area-01.png" alt="">
              <div class="pngText  div-centerY">
                編輯班級
              </div>
            </div>
          </div>  

          <div  v-if="!isPC" >
            <div class="PhoneArea" v-for="(data,i) in applyInfoData" :key="i"><!--手機板-->
              <div class="left">
                <span class="title">{{data.group}}</span>
              </div>
              <div class="between">
                <div class="left">
                  <span style="margin-right:20px">{{data.course}}</span>
                  <span>{{data.class}}</span>
                </div>
                <!-- <a href="https://www.teammodel.net/" target="_blank" class="otheBlueColor"> -->
                <div @click="gotoTeammodel('%2Fhome%2FprivExam')" class="otheBlueColor" style="cursor: pointer;">
                  詳細數據
                </div>
              </div>
              <div v-if="PageStatus === 2" class="left">
                <div class="stressColor">
                  {{ data.stage }}
                  <!-- <span class="gray1Color" v-if="data.stageStatus==='undo'">{{ stageTxtArry[0] }}</span>
                  <div class="gray1Color" v-else-if="data.stageStatus==='doing'">{{ stageTxtArry[1] }}</div>
                  <div class="gray1Color"  v-else-if="data.stageStatus==='complete'">{{ stageTxtArry[2] }}</div>
                  <span class="gray1Color" v-else-if="data.stageStatus==='disqualify'">{{ stageTxtArry[3] }}</span> -->
                </div>
              </div>
              <div class="line" v-if="i!==(applyInfoData.length-1)"></div>
            </div>
          </div>
          <div v-else class="tableArea"><!--電腦版-->
            <el-table :data="applyInfoData" style="width: 100%">              
              <el-table-column prop="group" label="報名組別" />
              <el-table-column prop="course" label="班級課程"  />
              <el-table-column prop="class" label="名單" />
              <el-table-column prop="stage" label="進行階段" v-if="PageStatus === 2">
                <template #default="scope">
                  <div class="stressColor">
                    {{ scope.row.stage }}
                    <!-- <div class="gray1Color" v-if="scope.row.stageStatus==='undo'">{{ stageTxtArry[0] }}</div>
                    <div class="gray1Color" v-else-if="scope.row.stageStatus==='doing'">{{ stageTxtArry[1] }}</div>
                    <div class="gray1Color" v-else-if="scope.row.stageStatus==='complete'">{{ stageTxtArry[2] }}</div>
                    <div class="gray1Color" v-else-if="scope.row.stageStatus==='disqualify'">{{ stageTxtArry[3] }}</div> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if="PageStatus === 2"
                label="" width="100">
                <template #default="">
                  <!-- <a href="https://www.teammodel.net/" target="_blank" class="otheBlueColor"> -->
                  <div @click="gotoTeammodel('%2Fhome%2FprivExam')" class="otheBlueColor" style="cursor: pointer;">                  
                    詳細數據
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column v-if="PageStatus === 2"
                label="操作" width="100">
                <template #default="">
                  <button  @click="goToUrl('Activity_Data')" class="stressColor">
                    參賽數據
                  </button>
                </template>
              </el-table-column> -->
            </el-table>
          </div>   
        </div>   
      </div>
    </div>

    <!-- <div class="VideoDiv div-center" >
      <iframe :class="isPC ? 'Video':'VideoPhone'" src="https://www.youtube.com/embed/ND7WSaIcarw?si=lLxGb3aHIlbMMbEn" 
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div> -->
    <div v-if="isPC" class="div-center" style="width: 100%;height: 602px;margin-top: 50px;">
      <div  style="text-align: center; ">
              <div style="font-size: 25px;font-weight: 700;margin-bottom: 20px">2024全國好小子活動已圓滿結束，特開放熱身練習試卷檔案提供教師們
                <a :href="getfileUrl()" style="color: #2d8cf0;" target="_blank">下載</a>                
                體驗！<br></div>    
              <img class="img" src="../../assets/img/20250103001.png" alt="" style="width: 100%;height: 100%;">         
      </div>      
    </div>
    <div v-else class="div-center" style="width: 80%;height: 250px;margin-top: 50px;margin-left: 10%;margin-right: 10%;">
      <div  style="text-align: center; ">
              <div style="font-size: 18px;font-weight: 700;margin-bottom: 20px">2024全國好小子活動已圓滿結束，特開放熱身練習試卷檔案提供教師們
                <a :href="getfileUrl()" style="color: #2d8cf0;"  target="_blank">下載</a>
                體驗！<br></div>    
              <img class="img" src="../../assets/img/20250103001.png" alt="" style="width: 100%;height: 215px">         
      </div>      
    </div>
    <div class="oneLine div-center">
      <div :class="['joinButtonArea', !isPC &&'div-center']" >
        <div :class="['joinButton', 'downLoad', 'load', 'whiteColor', 'div-center', isPC && 'PC']" style="background-color: #F5A056;margin-right:0;"  >
          <a :href="getfileUrl()" target="_blank">
            <div class="txt div-centerY YUnset" style="color:white;">
              下載試卷HTEX檔
              <el-icon><ArrowRightBold /></el-icon>
            </div> 
          </a>
        </div>
        <div >
          ※此檔案須於HiTeach內開啟
        </div>
      </div>      
      <div :class="['joinButtonArea', !isPC &&'div-center']" v-show="isReviewer" >
        <div :class="['joinButton', 'downLoad', 'load', 'whiteColor', 'div-center', isPC && 'PC']"  >
          <a href='javascript:void(0)' @click="gotoTeammodel('%2Fhome%2FhtExamMark')">
            <div class="txt div-centerY YUnset" style="color:white;">
              評審閱卷
              <el-icon><ArrowRightBold /></el-icon>
            </div> 
          </a>
        </div>
      </div>
    </div>

    <div class="div-center">
      <div class="cotentDiv oneLine">
        <div class="tableDiv right">
          <div class="titleDiv winArea  oneLine ">
            <div class="title oneLine div-centerY YUnset">
              <img class="iconPic" src="../../assets/img/awards_medal.png" alt=""> 
              得獎名單：
            </div>
          </div>
          <div v-if="isPC"><!--電腦板-->
            <div v-if="localRead" style="text-align: left;">
              <div>恭喜您的班級獲獎！！！頒獎典禮與班級代表挑戰賽將於11/09(六) 9:00 ~ 12:00 在新竹縣安興國小舉行。<br></div>
              <div>
                ※請於10/30(三) 前回填 
                <a target="_blank" href="https://forms.gle/DDuJCDkidYAc7iyq8" style="color: #f59f56">2024全國閱讀好小子頒獎&挑戰賽確認表</a>
                以利活動後續作業。
              </div>
            </div>
            <div :class="i==0 ? 'winCotent div-centerY':'winCotent div-centerY'" v-for="(data,i) in FractionData" :key="i">
              <div class="ContestTitle isPc oneLine">
                {{data.title}}
              </div>
              <div class="tableCss">                
                <el-table :data="data.data">
                  <el-table-column prop="rank" label="排名" width="180">
                    <template #default="scope" >
                      <div class="oneLine">
                        <img v-if="scope.row.rank==='1'" class="iconPic" src="../../assets/img/awards/awards_trophy1.png" alt=""> 
                        <img v-else-if="scope.row.rank==='2'" class="iconPic" src="../../assets/img/awards/awards_trophy2.png" alt=""> 
                        <img v-else-if="scope.row.rank==='3'" class="iconPic" src="../../assets/img/awards/awards_trophy3.png" alt=""> 
                        <img v-else-if="scope.row.rank==='excellent'" class="iconPic" src="../../assets/img/awards/awards_decoration1.png" alt=""> 
                        <img v-else-if="scope.row.rank==='Shortlisted'" class="iconPic" src="../../assets/img/awards/awards_decoration2.png" alt=""> 
                        <span class="otheBlueColor winTxt" v-if="scope.row.rank==='1'">第一名</span>
                        <span class="otheBlueColor winTxt" v-if="scope.row.rank==='2'">第二名</span>
                        <span class="otheBlueColor winTxt" v-if="scope.row.rank==='3'">第三名</span>
                        <span class="otheBlueColor winTxt" v-if="scope.row.rank==='excellent'">優秀獎</span>
                        <span class="otheBlueColor winTxt" v-if="scope.row.rank==='Shortlisted'">入圍獎</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="school" label="學校"  />
                  <el-table-column prop="class" label="班級名稱" width="200" />
                  <el-table-column prop="TName" label="帶隊教師" width="100">
                    <template #default="scope">
                      <div class="otheBlueColor">{{ scope.row.TName }}</div>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="tmid" label="TM ID"  width="150">
                    <template #default="scope">
                      <div class="gray1Color">{{ scope.row.tmid }}</div>
                    </template>
                  </el-table-column>                   -->
                </el-table>
              </div>
            </div>
          </div>
          <div v-else><!--手機板-->
            <div v-if="localRead" style="text-align: left;">
              <div>恭喜以下獲獎班級，頒獎典禮與班級代表挑戰賽將於11/09(六) 9:00 ~ 12:00 在新竹縣安興國小舉行。<br></div>
              <div style="margin-top: 5px;">
                ※請於10/30(三) 前回填 
                <a target="_blank" href="https://forms.gle/DDuJCDkidYAc7iyq8" style="color: #f59f56">2024全國閱讀好小子頒獎&挑戰賽確認表</a>
                以利活動後續作業。
              </div>
            </div>
            <div :class="i==0 ? 'winCotent div-centerY ':'winCotent div-centerY'" v-for="(temp,i) in FractionData" :key="i">
              <div class="ContestTitle oneLine">
                {{temp.title}}
              </div>
              <div v-for="(thisData,k) in temp.data" :key="k" class="tableCssPhone">
                <div class="Div1 between">
                  <div class="oneLine div-centerY2">
                    <img v-if="thisData.rank==='1'" class="iconPic" src="../../assets/img/awards/awards_trophy1.png" alt=""> 
                    <img v-else-if="thisData.rank==='2'" class="iconPic" src="../../assets/img/awards/awards_trophy2.png" alt=""> 
                    <img v-else-if="thisData.rank==='3'" class="iconPic" src="../../assets/img/awards/awards_trophy3.png" alt=""> 
                    <img v-else-if="thisData.rank==='excellent'" class="iconPic" src="../../assets/img/awards/awards_decoration1.png" alt=""> 
                    <img v-else-if="thisData.rank==='Shortlisted'" class="iconPic" src="../../assets/img/awards/awards_decoration2.png" alt=""> 
                    <span class="otheBlueColor winTxt" v-if="thisData.rank==='1'">第一名</span>
                    <span class="otheBlueColor winTxt" v-if="thisData.rank==='2'">第二名</span>
                    <span class="otheBlueColor winTxt" v-if="thisData.rank==='3'">第三名</span>
                    <span class="otheBlueColor winTxt" v-if="thisData.rank==='excellent'">優秀獎</span>
                    <span class="otheBlueColor winTxt" v-if="thisData.rank==='Shortlisted'">入圍獎</span>
                  </div>
                  <div class="Div2">
                    <div class="left">{{thisData.school}}</div> 
                    <div class="left">
                      <span class="gray1Color rightSet">{{thisData.class}}</span>
                      <span class="otheBlueColor">{{thisData.TName}}</span>
                      <!-- <span class="gray1Color">{{thisData.tmid}}</span> -->
                    </div>
                  </div>
                </div>
                <p class="line"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tableDiv right">
          <div class="titleDiv  between ">
            <div class="title oneLine div-centerY YUnset">
              <img class="iconPic" src="../../assets/img/awards_trophies.png" alt=""> 
              全國閱讀好小子優選學校：
            </div>
          </div>
          <div class="schoolList" :style="isPC ? 'margin-left: 30px':''">
            <div class="list">
              <img class="iconPic" src="../../assets/img/symbol_starblue.png" alt=""> 
              高雄市前金國小
              <img class="iconPic" src="../../assets/img/symbol_starblue.png" alt="">
            </div>
            <div class="list">
              <img class="iconPic" src="../../assets/img/symbol_stargreen.png" alt=""> 
              高雄市東光國小
              <img class="iconPic" src="../../assets/img/symbol_stargreen.png" alt=""> 
            </div>
            <div class="list">
              <img class="iconPic" src="../../assets/img/symbol_starred.png" alt=""> 
              桃園市同德國中
              <img class="iconPic" src="../../assets/img/symbol_starred.png" alt=""> 
            </div>
            <div class="list">
              <img class="iconPic" src="../../assets/img/symbol_staryellow.png" alt=""> 
              臺北市萬華國中
              <img class="iconPic" src="../../assets/img/symbol_staryellow.png" alt=""> 
            </div>
          </div>
        </div>
        <div class="tableDiv right">
          <div class="titleDiv  between ">
            <div class="title oneLine div-centerY YUnset">
              <img class="iconPic" src="../../assets/img/other_book.png" alt=""> 
              重要時程：
            </div>
            <!-- <div class=" gray1Color info">
              ※提醒：各階段的開始及結束將會寄送至您教育雲帳號所綁定的email
            </div> -->
          </div>
          <div v-if="!isPC"><!--手機板-->
            <div v-for="(data,i) in PlanData" :key="i" class="scheduleArea">
              <div class="between titleArea">
                <div class="oneLine titleSet">
                  <div><span class="otheBlueColor stepName">{{data.stepName}}</span></div>
                  <div class="current_progress" v-if="data.isNow">※目前活動進度</div><!---->
                </div>
                <div style="font-size: 20px;" v-if="isJoin && PageStatus!==0"><!---->
                  <a :href="data.url" target="_blank" v-if="data.urlOpen && data.url!==''"><!---->
                    <el-icon ><Download /></el-icon>
                  </a>
                </div>
              </div>
              <div class="card">
                <div class="between place">                
                  <div>地點：<span v-html="data.location"></span></div>
                </div>
                <div class="between time" style="color:@fontColor-gray1">
                  <div>{{data.startTime}} ~ {{data.endTime}}</div>                
                </div>  
                <div class="description" v-html="data.description"></div>
              </div>
              <div class="triangleArea div-center" v-if="i!==(PlanData.length-1)">
                <div>
                  <div class="triangle "></div>
                  <!-- <div class="triangle small"></div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="tableArea" v-else><!--電腦版-->
            <el-table :data="PlanData" style="width: 100%" :row-class-name="PlanDataRowClass">
              <!-- <el-table-column prop="step" label="step" :width="isNarrow ? 40 : 60" /> -->
              <el-table-column prop="startTime" label="時程">
                <template #default="scope">
                  <div>{{scope.row.startTime}} ~ {{scope.row.endTime}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="stepName" label="階段" :width="isNarrow ? 'auto' : 150">
                <template #default="scope">
                  <div class="otheBlueColor">{{ scope.row.stepName }}</div>
                  <span v-if="scope.row.isNow" class="current_progress"> 目前活動進度</span>
                </template>
              </el-table-column>
              <el-table-column prop="location" label="地點" :width="isNarrow ? 'auto' : 200">
                <template #default="scope">
                  <div class="info-cell" v-html="scope.row.location"></div>
                </template>
              </el-table-column>
              <el-table-column prop="description" label="說明" >
                <template #default="scope">
                  <!-- <div class="info-cell">{{ scope.row.description }}</div> -->
                  <div class="info-cell" v-html="scope.row.description"></div>
                </template>
              </el-table-column>
              <el-table-column v-if="isJoin && PageStatus!==0" prop="url" label="下載" width="70">
                <template #default="scope">
                  <div class="URL" style="font-size: 20px;">
                    <a :href="scope.row.url" target="_blank" v-if="scope.row.urlOpen && scope.row.url!==''">
                      <el-icon ><Download /></el-icon>
                    </a>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="tableDiv right">
          <div class="titleDiv  between ">
            <div class="title oneLine div-centerY YUnset">
              <img class="iconPic" src="../../assets/img/other_light.png" alt=""> 
              活動說明：
            </div>
          </div>
          <div class="QA_Content" :style="isPC ? 'margin-left: 30px':''">
            <div class="list">一、	教師如何<a href="https://www.habook.com/zh-tw/download.php?act=view&id=67" target="_blank">下載安裝HiTeach</a>？</div>
            <div class="list">二、	教師如何<a href="https://www.habook.com/zh-tw/faq.php?act=view&id=239" target="_blank">使用HiTeach建立參賽班級名單</a>？</div>
            <div class="list">三、	2024全國閱讀好小子活動報名與測驗<a href="https://www.habook.com/zh-tw/faq.php?act=view&id=263" target="_blank">進行流程說明</a></div>
          </div>
        </div>
        <div class="tableDiv right">
          <div class="titleDiv  between ">
            <div class="title oneLine div-centerY YUnset">
              <img class="iconPic" src="../../assets/img/other_bell.png" alt=""> 
              活動說帖：
            </div>
          </div>
          <div class="QA_Content" v-if="isPC" style="margin-left: 30px"><!--電腦板-->
            <div class="list">一、	計畫團隊給校長的一封信：
              <span >
                <a href="https://www.habook.com/data/download/file/letter%20to%20principal.docx" target="_blank">下載Word</a> |
                <a href="https://www.habook.com/data/download/file/letter%20to%20principal.pdf" target="_blank">下載PDF</a>
              </span>
            </div>
            <div class="list">二、	計畫團隊給老師的一封信：              
              <span>
                <a href="https://www.habook.com/data/download/file/letter%20to%20teacher.docx" target="_blank">下載Word</a> |
                <a href="https://www.habook.com/data/download/file/letter%20to%20teacher.pdf" target="_blank">下載PDF</a>
              </span>
            </div>
            <div class="list">三、	老師給家長的一封信：
              <span >
                <a href="https://www.habook.com/data/download/file/letter%20to%20parents.docx" target="_blank">下載Word</a> |
                <a href="https://www.habook.com/data/download/file/letter%20to%20parents.pdf" target="_blank">下載PDF</a>
              </span>            
            </div>
            <div class="list">四、	老師給學生的一封信：
              <span>
                <a href="https://www.habook.com/data/download/file/letter%20to%20students.docx" target="_blank">下載Word</a> |
                <a href="https://www.habook.com/data/download/file/letter%20to%20students.pdf" target="_blank">下載PDF</a>
              </span>            
            </div>
          </div>
          <div class="QA_Content" v-else ><!--手機板-->
            <div class="list">一、	計畫團隊給校長的一封信：
              <span >
                <a href="https://www.habook.com/data/download/file/letter%20to%20principal.docx" target="_blank">Word</a> |
                <a href="https://www.habook.com/data/download/file/letter%20to%20principal.pdf" target="_blank">PDF</a>
              </span>
            </div>
            <div class="list">二、	計畫團隊給老師的一封信：   
              <span>
                <a href="https://www.habook.com/data/download/file/letter%20to%20teacher.docx" target="_blank">Word</a> |
                <a href="https://www.habook.com/data/download/file/letter%20to%20teacher.pdf" target="_blank">PDF</a>
              </span>
            </div>
            <div class="list">三、	老師給家長的一封信：
              <span>
                <a href="https://www.habook.com/data/download/file/letter%20to%20parents.docx" target="_blank">Word</a> |
                <a href="https://www.habook.com/data/download/file/letter%20to%20parents.pdf" target="_blank">PDF</a>
              </span>              
            </div>
            <div class="list">四、	老師給學生的一封信：
              <span>
                <a href="https://www.habook.com/data/download/file/letter%20to%20students.docx" target="_blank">Word</a> |
                <a href="https://www.habook.com/data/download/file/letter%20to%20students.pdf" target="_blank">PDF</a>
              </span>              
            </div>
          </div>
        </div>  
        <!-- <div class="tableDiv right" id="importentTag">
          <div class="titleDiv  between ">
            <div class="title oneLine div-centerY YUnset">
              <img class="iconPic" src="../../assets/img/other_hourglass.png" alt=""> 
              決賽重要事項：             
            </div>
          </div>
          <div class="QA_Content" :style="isPC ? 'margin-left: 30px':''">
            <div class="list">請務必確認教師使用的HiTeach更新至5.3.0.0009，以下圖示。</div>
            <img class="picShow" src="../../assets/img/HTupdateInfo.jpg" alt=""> 
          </div>
        </div> -->
        <div class="tableDiv right" v-show="false">
          <div class="titleDiv  between " id="textPreview">
            <div class="title oneLine div-centerY YUnset" id="importentTag">
              <img class="iconPic" src="../../assets/img/other_hourglass.png" alt=""> 
              決賽文本及答案公布：
            </div>
          </div>
          <!--電腦板-->
          <div class="QA_Content"  v-if="isPC" style="margin-left: 30px">
            <!-- <div class="list step">班級閱讀熱身練習用，記得先下載！</div> -->
            <div class="list">1 國小低年級組-媽媽動物背寶寶：
              <a href="https://drive.google.com/drive/folders/1hdSxSCAltyZEFspKQvCxMHTMl4LaPK3r?usp=sharing" target="_blank">連結</a> 
            </div>
            <div class="list">2 國小低年級組-等一下：
              <a href="https://drive.google.com/drive/folders/1QMU0l9GfS9ymXXmoMLJN-sSrJyP4LKes?usp=sharing" target="_blank">連結</a> 
            </div>
            <div class="list">3 國小中年級組-大家一起泡溫泉：
              <a href="https://drive.google.com/drive/folders/1iebU-MjBm22UK2spZ4-8Bs-ORz0efNoB?usp=sharing" target="_blank">連結</a> 
            </div>
            <div class="list">4 國小中年級組-圖像新聞：
              <a href="https://drive.google.com/drive/folders/1V908ZmpDPd9kwZB2t5da9H_3F3a9-lqV?usp=sharing" target="_blank">連結</a>
            </div>
            <div class="list">5 國小高年級組-國際：
              <a href="https://drive.google.com/drive/folders/1N3496042WW9ukco3ctqVMu0yiIhrYgZ5?usp=sharing" target="_blank">連結</a>
            </div>
            <div class="list">6 國小高年級組-糖果星球：
              <a href="https://drive.google.com/drive/folders/17_tobPN75IO7-5mU0mRygR_Bq8tHUUri?usp=sharing" target="_blank">連結</a>
            </div>
            <div class="list">7 國中組-看奧運可以拍照或錄影嗎：
              <a href="https://drive.google.com/drive/folders/15vFo2XfkD9bIcpKQviV8KNPTidl41wQW?usp=sharing" target="_blank">連結</a>
            </div>
            <div class="list">8 國中組-雪中驚嘆：一個熱帶人的冰雪奇緣：
              <a href="https://drive.google.com/drive/folders/1IteL3Gw64WQYwx60OminsBMb1-AAkE-x?usp=sharing" target="_blank">連結</a>
            </div>            
          </div>
          <!--手機板-->
          <div class="QA_Content" id="textPreview" v-else>
            <!-- <div class="list step">班級閱讀熱身練習用，記得先下載！</div> -->
            <div class="list">一、國小低年級組 
              <div style="margin-left: 20px;">
                ＜媽媽動物背寶寶＞
                <a href="https://drive.google.com/drive/folders/1hdSxSCAltyZEFspKQvCxMHTMl4LaPK3r?usp=sharing" target="_blank">連結</a> 
              </div>
            </div>
            <div class="list">二、國小低年級組 
              <div style="margin-left: 20px;">
                ＜等一下＞
                <a href="https://drive.google.com/drive/folders/1QMU0l9GfS9ymXXmoMLJN-sSrJyP4LKes?usp=sharing" target="_blank">連結</a> 
              </div>
            </div>
            <div class="list">三、國小中年級組 
              <div style="margin-left: 20px;">
                ＜大家一起泡溫泉＞
                <a href="https://drive.google.com/drive/folders/1iebU-MjBm22UK2spZ4-8Bs-ORz0efNoB?usp=sharing" target="_blank">連結</a>
              </div>
            </div>
            <div class="list">四、國小中年級組 
              <div style="margin-left: 20px;">
                ＜圖像新聞＞
                <a href="https://drive.google.com/drive/folders/1V908ZmpDPd9kwZB2t5da9H_3F3a9-lqV?usp=sharing" target="_blank">連結</a>
              </div>
            </div>
            <div class="list">五、國小高年級組 
              <div style="margin-left: 20px;">
                ＜國際＞
                <a href="https://drive.google.com/drive/folders/1N3496042WW9ukco3ctqVMu0yiIhrYgZ5?usp=sharing" target="_blank">連結</a>
              </div>
            </div>
            <div class="list">六、國小高年級組 
              <div style="margin-left: 20px;">
                ＜糖果星球＞
                <a href="https://drive.google.com/drive/folders/17_tobPN75IO7-5mU0mRygR_Bq8tHUUri?usp=sharing" target="_blank">連結</a>
              </div>
            </div>
            <div class="list">七、國中組 
              <div style="margin-left: 20px;">
                ＜看奧運可以拍照或錄影嗎＞
                <a href="https://drive.google.com/drive/folders/15vFo2XfkD9bIcpKQviV8KNPTidl41wQW?usp=sharing" target="_blank">連結</a>
              </div>
            </div>
            <div class="list">八、國中組 
              <div style="margin-left: 20px;">
                ＜雪中驚嘆：一個熱帶人的冰雪奇緣＞
                <a href="https://drive.google.com/drive/folders/1IteL3Gw64WQYwx60OminsBMb1-AAkE-x?usp=sharing" target="_blank">連結</a>
              </div>
            </div>            
          </div>
        </div>
        <div class="tableDiv planDiv right">
          <div class="titleDiv  between " id="textPreview">
            <div class="title oneLine div-centerY YUnset">
              <img class="iconPic" src="../../assets/img/awards_medal.png" alt=""> 
              名師邀你一起參與：
            </div>
          </div>
          <div class="movie_Content"  v-if="isPC" style="margin-left: 30px"><!--電腦板-->
            <div class="oneLine pc">
              <div>
                <iframe src="https://www.youtube.com/embed/ovVyEc21tR8?si=a63UWsPv7V40KhYt"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div>國中組：黃于珊 老師</div>
              </div>
              <div>
                <iframe src="https://www.youtube.com/embed/VbZlNmp1O_A?si=jqgum4p4AzB7SYg7" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div>國小低年級組：林明君 老師</div>
              </div>
              <div>
                <iframe src="https://www.youtube.com/embed/ba3Zg-sRQKQ?si=orcA4WO6esVnDJDq" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div>國小高年級組：劉文尚 老師</div>
              </div>
              <div>
                <iframe src="https://www.youtube.com/embed/ihxNU2VC2QY?si=Mr7PdR1P99CKMwa7" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div>國小中年級組：蔡依蘭 主任</div>
              </div>              
            </div>            
          </div>
          <div class="movie_Content" id="textPreview" v-else><!--手機板-->
            <div class="phone">            
              <div>
                <iframe src="https://www.youtube.com/embed/ovVyEc21tR8?si=a63UWsPv7V40KhYt"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div>國中組：黃于珊 老師</div>
              </div>
              <div>
                <iframe src="https://www.youtube.com/embed/VbZlNmp1O_A?si=jqgum4p4AzB7SYg7" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div>國小低年級組：林明君 老師</div>
              </div>
              <div>
                <iframe src="https://www.youtube.com/embed/ba3Zg-sRQKQ?si=orcA4WO6esVnDJDq" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div>國小高年級組：劉文尚 老師</div>
              </div>
              <div>
                <iframe src="https://www.youtube.com/embed/ihxNU2VC2QY?si=Mr7PdR1P99CKMwa7" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div>國小中年級組：蔡依蘭 主任</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="div-center signDataButtonDiv" v-if="PageStatus!==0">
      <div class="picButton big "  @click="goToUrl('/Binding_Choose')">
        <img class="buttonImg" src="../../assets/img/area-08.png" alt="">
        <div class="pngText big div-centerY">
          參賽數據
        </div>
      </div>
    </div> -->
    <div :class="isPC ? 'footerDiv div-center' : 'footerDiv phone div-center' ">
      <div class=" txtDiv">
        主辦單位：臺灣科技領導與教學科技發展協會、小貓頭鷹智慧聯盟校<br/>
        指導/協辦：桃園市政府教育局、新竹縣政府教育局、臺中市政府教育局、南投縣政府教育處、屏東縣政府教育處、新竹縣數位學習推動辦公室、雲林縣數位學習推動辦公室、澎湖縣數位學習推動辦公室 <br/>
        合作單位：小魯文化事業股份有限公司、中華教育創新發展學會、水滴文化 - 城邦文化事業股份有限公司、東方出版社、均仕邦有限公司、財團法人國語日報社、奧林文化事業有限公司、大穎文化事業股份有限公司、維京文化傳播股份有限公司、臺灣麥克股份有限公司、趣·讀冊、邁達特METAMatch生態圈媒合平台<br/>
        支持單位：全球醍摩豆智慧教育研究院
      </div>
    </div>

    <!-- 對話框跳窗-加入班級 -->
    <el-dialog v-model="dialogAddClassCtrl" title="參賽班級" class="applyTableDiv div-centerY">
      <div class="content div-center2">
        <div class="info">          
          1.下載HiTeach <a class="urlCss" href="https://www.habook.com/zh-tw/download.php?act=view&id=67/" target="_blank">GO</a>! 
          <br>
          2.如何建立參賽班級？
          <a class="urlCss" href="https://www.habook.com/zh-tw/faq.php?act=view&id=239" target="_blank">GO</a>!
          <br>
          3.如果報名後參賽班級有變動(增加或減少學生)，要再回來此處<b><u>更新資料並重新選擇</u></b>喔！
        </div>
        <div class="oneLine">
          <div class="chooseArea">
            <span>
              報名組別：
            </span>
            <el-cascader v-model="signUpGroup" :options="signUpGroupOptions" class="signUpChooseDiv" @change="signUpGroupFun"/>
          </div>
          <div class="chooseArea">
            <span>
              班級課程：
            </span>
            <el-cascader v-model="signUpClass" :options="signUpClassOptionsShow" class="signUpChooseDiv" @change="signUpClassFun" />
          </div>
          <el-button type="primary" @click="updateDataFun">更新資料</el-button>
        </div>
        <div class="div-center">
          <el-table :data="applyTableData" class="signUpTableDiv">
            <el-table-column prop="name" label="名單" >
              <template #default="scope">
                <div>名單：{{ scope.row.name }}</div>
                <div>學生人數：{{ scope.row.students }}人</div>
              </template>
            </el-table-column>
            <el-table-column label="選擇" width="100">
              <template #default="scope">
                <el-checkbox v-model="scope.row.selected" :disabled="scope.row.students<=0"></el-checkbox><!--@change="handleSelectionChange(scope.$index)"-->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="dialogFooter div-centerY">
        <div>
          <el-button @click="dialogAddClassCancel">取消</el-button>
          <el-button type="primary" @click="dialogAddClassConfirm">確定</el-button>
        </div>        
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref,reactive,onMounted,onUnmounted,inject,  } from 'vue';//inject,reactive,ref,computed,h
//import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';//ElMessageBox, 
import profile from "../../api/profile";
import {Base64} from 'js-base64'
import api from "@/api";
import jwtDecode from 'jwt-decode'
//const routers = useRouter();

// 動態生成文件 URL 並進行編碼
//const fileUrl = `/files/${encodeURI("全國好小子熱身練習試卷.htex")}`;
//const fileUrl = `/files/${encodeURI("test.htex")}`;
//const fileUrl = `/files/${encodeURI("fileName.pdf")}`;

let isReviewer = ref(false);//是否是評審
let webID= reactive({test:'37db8a66-660c-43a5-b8ce-6686c070973e',Official:'27d4462b-cab4-4c70-84fa-13ba89af2daa'});
//測試站ID 37db8a66-660c-43a5-b8ce-6686c070973e  / 正式站ID  ??
let ActivityID= ref('');
let testWebList = ref(['localhost','community-test']);//測試站的網站名單
let isTestWeb = ref(false);//是否是正式站
let ActiveStart=ref(false);//活動是否開始，用 schedule的type=join判斷
let PageStatus = ref(0);//0我要報名，1已經報名，2活動開跑(不能編輯班級)
let isJoin = ref(false);//判斷是否報名
/* ※↑↑若要看PageStatus為2階段，記得把 applyInfoData 內容解註解 */

let tmdid = ref('');//TMID
let ecName = ref('');//使用者名稱
let ecEmail = ref('');//使用者email
let scope = ref('');//個人/學校 "private":個人 "school":學校
let isPC = ref(true);//判斷是否是PC
let localRead = ref(false);//判斷是否有可以看到決賽地點的權限

const t = inject('$t');//三國語系

const serverLocation = "global";// 國際站設定
const clientID = profile.clientID[serverLocation];// clientID設定
import { post } from "@/api/http";
const coreAPIUrl = profile.apiUrl[serverLocation];// API路由設定

//得獎相關↓↓-------------------------------
let FractionData = reactive([
  {
    title: '低年級組',
    data: [
      { rank: '1', school: '彰化縣華南國小', TName: '黃怡菁', tmid: '1726732781', class: '二年忠班' },
      { rank: '2', school: '高雄市前金國小', TName: '高敏惠', tmid: '1646890193', class: '202' },
      { rank: '3', school: '新北市濂洞國小', TName: '謝矞心', tmid: '1726462570', class: '二年忠班' },
      { rank: 'excellent', school: '高雄市前金國小', TName: '高敏惠', tmid: '1646890193', class: '201' },
      { rank: 'excellent', school: '高雄市東光國小', TName: '謝美雲', tmid: '1716358850', class: '213' },
      { rank: 'excellent', school: '新北市集美國小', TName: '王瓊儀', tmid: '1700666602', class: '208' },
      { rank: 'excellent', school: '桃園市元生國小', TName: '馬金花', tmid: '1658129344', class: '206' },
      { rank: 'excellent', school: '國立臺南大學附小', TName: '許琬菡', tmid: '1670289969', class: '二年乙班' },
      { rank: 'excellent', school: '高雄市東光國小', TName: '蔡瑞芬', tmid: '1655941360', class: '201' },
      { rank: 'Shortlisted', school: '臺中市建國國小', TName: '王雅嫃', tmid: '1726017391', class: '二年丁班' },
      { rank: 'Shortlisted', school: '桃園市龍星國小', TName: '陳健達', tmid: '1659407614', class: '一年一班' },
      { rank: 'Shortlisted', school: '高雄市前金國小', TName: '林明君', tmid: '1641535594', class: '一年一班' }
    ]
  },
  {
    title: '中年級組',
    data: [
      { rank: '1', school: '桃園市大華國小', TName: '何世甯', tmid: '1593638787', class: '301' },
      { rank: '2', school: '屏東縣玉光國小', TName: '張劭永', tmid: '1726736810', class: '四年乙班' },
      { rank: '3', school: '臺中市西屯國小', TName: '洪婉琪', tmid: '1664957737', class: '406' },
      { rank: 'excellent', school: '臺北市博嘉實驗國小', TName: '鄭惠文', tmid: '1721869593', class: '四年甲班' },
      { rank: 'excellent', school: '嘉義市精忠國小', TName: '何美凰', tmid: '1654048189', class: '四年乙班' },
      { rank: 'excellent', school: '新北市深坑國小', TName: '李岩勳', tmid: '1589670915', class: '401' },
      { rank: 'excellent', school: '高雄市東光國小', TName: '李依霞', tmid: '1686887021', class: '小俠客的異想天地' },
      { rank: 'excellent', school: '國立臺中教大附小', TName: '陳慧如', tmid: '1724212556', class: '四年戊班' },
      { rank: 'excellent', school: '臺中市建國國小', TName: '王雅嫃', tmid: '1726017391', class: '四戊405' },
      { rank: 'Shortlisted', school: '高雄市鹽埕國小', TName: '陳莉芳', tmid: '1654843483', class: '402' },
      { rank: 'Shortlisted', school: '國立南科實中國小部', TName: '張雪莉', tmid: '1549804767', class: '401' },
      { rank: 'Shortlisted', school: '高雄市陽明國小', TName: '鍾淑薇', tmid: '1702618416', class: '407' },
      { rank: 'Shortlisted', school: '高雄市東光國小', TName: '林宛玲', tmid: '1649669031', class: '411' },
      { rank: 'Shortlisted', school: '嘉義縣中埔國小', TName: '曾淑蘭', tmid: '1654667055', class: '四甲' },
      { rank: 'Shortlisted', school: '嘉義縣中埔國小', TName: '洪碧純', tmid: '1654666733', class: '四乙' },
      { rank: 'Shortlisted', school: '高雄市東光國小', TName: '林宛玲', tmid: '1649669031', class: '410' },
      { rank: 'Shortlisted', school: '南投縣平林國小', TName: '郭碧娟', tmid: '1544196028', class: '四甲' },
      { rank: 'Shortlisted', school: '新竹縣竹東國小', TName: '邱葉欣燁', tmid: '1715146788', class: '東小悅閱' }
    ]
  },
  {
    title: '高年級組',
    data: [
      { rank: '1', school: '桃園市仁和國小', TName: '辛孟潔', tmid: '1694650008', class: '五年三班' },
      { rank: '2', school: '高雄市前金國小', TName: '謝紫湄', tmid: '1663461650', class: '509' },
      { rank: '3', school: '彰化縣潮洋國小', TName: '劉美祈', tmid: '1661159176', class: '五年忠班' },
      { rank: 'excellent', school: '高雄市前金國小', TName: '林琇雯', tmid: '1656593504', class: '511' },
      { rank: 'excellent', school: '高雄市前金國小', TName: '高敏惠', tmid: '1646890193', class: '605' },
      { rank: 'excellent', school: '新北市同榮國小', TName: '陳豫怡', tmid: '1683107470', class: '504' },
      { rank: 'excellent', school: '南投縣水尾國小', TName: '陳宜妏', tmid: '1537532507', class: '六年甲班' },
      { rank: 'excellent', school: '高雄市前金國小', TName: '王珮楹', tmid: '1656635537', class: '611' },
      { rank: 'excellent', school: '高雄市前金國小', TName: '高敏惠', tmid: '1646890193', class: '602' },
      { rank: 'Shortlisted', school: '南投縣北投國小', TName: '馮伊婷', tmid: '1710115967', class: '六年乙班' },
      { rank: 'Shortlisted', school: '新北市中和國小', TName: '林麗足', tmid: '1685854957', class: '613' },
      { rank: 'Shortlisted', school: '屏東縣崇蘭國小', TName: '林淨慧', tmid: '1726662471', class: '六年三班' },
      { rank: 'Shortlisted', school: '桃園市文化國小', TName: '王韋甯', tmid: '1726726376', class: '601' },
      { rank: 'Shortlisted', school: '新北市文德國小', TName: '蔡惠玉', tmid: '1632347716', class: '613' },
      { rank: 'Shortlisted', school: '臺北市明德國小', TName: '花梅真', tmid: '1546167623', class: '五年二班' },
      { rank: 'Shortlisted', school: '新北市實踐國小', TName: '徐麗萍', tmid: '1721279228', class: '607' },
      { rank: 'Shortlisted', school: '臺南市忠義國小', TName: '戴煜德', tmid: '1647840163', class: '501' },
      { rank: 'Shortlisted', school: '高雄市前金國小', TName: '楊婷婷', tmid: '1700025030', class: '603' }
    ]
  },
  {
    title: '國中組',
    data: [
      { rank: '1', school: '南投縣三光國中', TName: '蕭秀緞', tmid: '1544226221', class: '801' },
      { rank: '2', school: '嘉義縣義竹國中', TName: '謝淑媚', tmid: '1542604945', class: '803' },
      { rank: '3', school: '新北市忠孝國中', TName: '林佳樺', tmid: '1699752191', class: '907' },
      { rank: 'excellent', school: '新竹縣勝利國中', TName: '徐意竹', tmid: '1702344115', class: '712' },
      { rank: 'excellent', school: '臺北市龍門國中', TName: '陳慧玟', tmid: '1576738764', class: '815' },
      { rank: 'excellent', school: '嘉義縣義竹國中', TName: '龔寳仁', tmid: '1611800710', class: '801' },
      { rank: 'excellent', school: '臺北市龍門國中', TName: '陳慧玟', tmid: '1576738764', class: '802' },
      { rank: 'excellent', school: '南投縣三光國中', TName: '李欣怡', tmid: '1537326837', class: '701' },
      { rank: 'excellent', school: '新竹縣勝利國中', TName: '徐意竹', tmid: '1702344115', class: '711' },
      { rank: 'Shortlisted', school: '臺北市中崙高中', TName: '陳蕙瑤', tmid: '1658462991', class: '703' },
      { rank: 'Shortlisted', school: '桃園市同德國中', TName: '伍立心', tmid: '1701414715', class: '714' },
      { rank: 'Shortlisted', school: '桃園市同德國中', TName: '劉育昇', tmid: '1534945459', class: '706' },
      { rank: 'Shortlisted', school: '臺北市萬華國中', TName: '楊蕙昀', tmid: '1727331842', class: '704' },
      { rank: 'Shortlisted', school: '桃園市同德國中', TName: '馮尹君', tmid: '1699594211', class: '816' },
      { rank: 'Shortlisted', school: '臺北市麗山國中', TName: '陳怡秀', tmid: '1645447140', class: '704' },
      { rank: 'Shortlisted', school: '臺北市萬華國中', TName: '鄭美玲', tmid: '1726998240', class: '701' },
      { rank: 'Shortlisted', school: '臺北市萬華國中', TName: '蕭淑娟', tmid: '1727330979', class: '702' },
      { rank: 'Shortlisted', school: '臺北市中崙高中國中部', TName: '陳蕙瑤', tmid: '1658462991', class: '701' }
    ]
  }
]);

let groupListInfo = [//報名組別
  {name:'國小低年級組',testID:'55edeec3-a3cd-4117-b2e1-4e10c5b3d651',OfficialID:'a6504df5-9850-4ad5-abd2-0ac01b340d5c'},
  {name:'國小中年級組',testID:'06361f08-5108-464e-ba93-317e4c0248fd',OfficialID:'e753ae2d-0a32-4232-999d-092b8e965c82'},
  {name:'國小高年級組',testID:'d1409e4c-b0d0-45dd-ad94-3fe2fd89d9d6',OfficialID:'767337ef-7de6-4485-ba7e-ddd2cc317e07'},
  {name:'國中',testID:'0394efdd-9a97-460d-b77f-6f66307c1d5d',OfficialID:'d735d171-05f3-4501-a303-a66b566de8fa'},
];

let dialogAddClassCtrl = ref(false);
let classListCtrl= ref(false);

//src路徑
// function srcUrl(index){
//   index += 1 ;
//   if(index>8)index = 0;
//   return require(`../../assets/img/symbol/symbol_arrow_0${index}.png`);
// }

//得獎相關↑↑-------------------------------


//重要時程內容↓↓-------------------------------

//重要日程資料
let PlanData = reactive([//計畫資料
  {
    step: '1',
    stepStartTime:'1725854400000',//程式判斷時間 測試時間  1723564800   活動時間 1725854400000
    stepEndTime:'1727971199999',//程式判斷時間 1727539199999
    startTime:'113/09/09(一)',//顯示開始時間
    endTime:'113/09/28(六) 24:00',//顯示結束時間 9/28 23:59:59:999
    stepName: '報名時間',
    location:'線上報名',
    description:'以班級為單位報名，導師或授課教師在此報名參賽班級與名單。',
    blobsName:'',//下載檔案名稱
    url:'https://teammodel.blob.core.windows.net/event/goodkid/%E5%85%A8%E5%9C%8B%E9%96%B1%E8%AE%80%E5%A5%BD%E5%B0%8F%E5%AD%90%E7%B5%A6OO%E7%9A%84%E4%B8%80%E5%B0%81%E4%BF%A1.zip?sv=2023-01-03&st=2024-08-08T09%3A20%3A26Z&se=2024-11-12T09%3A20%3A00Z&sr=b&sp=r&sig=PxACA8MwViHOOK%2F9lng7p5vh4TxviPdQXqS51V%2BKL60%3D',//下載檔案連結
    urlOpen:false,//是否提供下載(要判斷兩日前)
    isNow:false,
  },
  {
    step: '2',
    stepStartTime:'1727971200000',//程式判斷時間 1727625600000
    stepEndTime:'1729526399000',//程式判斷時間
    startTime:'113/09/30(一)',
    endTime:'113/10/21(一)',
    stepName: '班級閱讀熱身練習',
    location:'班級上課教室 ',
    description:'利用上學期間，安排兩次班級閱讀與評量活動，每次閱讀一篇素養文本，共兩篇素養文本。<br>'+
      '兩篇文本題目都在HiTeach上公布囉！趕快登入HiTeach直接開始熱身練習吧～<br>'+
      '(<a target="_blank" href="https://teammodelblob.teammodel.net/event/goodkid/%E5%85%A8%E5%9C%8B%E9%96%B1%E8%AE%80%E5%A5%BD%E5%B0%8F%E5%AD%90-HiTeach%E7%86%B1%E8%BA%AB%E7%B7%B4%E7%BF%92%E5%8F%8A%E6%B1%BA%E8%B3%BD%E6%93%8D%E4%BD%9C%E6%B5%81%E7%A8%8B.pdf?sv=2023-01-03&st=2024-10-04T02%3A52%3A58Z&se=2025-01-01T02%3A52%3A00Z&sr=b&sp=r&sig=g2%2BQelrF0N%2B6V4c%2FHm6aCl0EgROf6UrvMKU7%2BOqSy%2B4%3D" style="color: #f59f56">HiTeach測驗說明</a>)',
    blobsName:'',//下載檔案名稱
    url:'',//下載檔案連結
    urlOpen:false,//是否提供下載(要判斷兩日前)
    isNow:false,//是否為當下
  },
  {
    step: '3',
    stepStartTime:'1729612800000',//程式判斷時間
    stepEndTime:'1729764000000',//程式判斷時間
    startTime:'113/10/23(三)',
    endTime:'113/10/24(四)18:00',
    stepName: '班級閱讀決賽',
    location:'班級上課教室',
    description:'參賽班級在決賽時間內，進行班級閱讀與評量活動，共兩份文本測驗。<br>'+
      '決賽階段不公布文本，活動開始時直接進入HiTeach測驗呦！<br>'+
      '<b><u>※需確認HiTeach更新至5.3.0.0009 </u></b>'+
      '(<a href="#importentTag" style="color: #f59f56">如何更新</a>)<br> '+      
      '(<a target="_blank" href="https://teammodelblob.teammodel.net/event/goodkid/%E5%85%A8%E5%9C%8B%E9%96%B1%E8%AE%80%E5%A5%BD%E5%B0%8F%E5%AD%90-HiTeach%E7%86%B1%E8%BA%AB%E7%B7%B4%E7%BF%92%E5%8F%8A%E6%B1%BA%E8%B3%BD%E6%93%8D%E4%BD%9C%E6%B5%81%E7%A8%8B.pdf?sv=2023-01-03&st=2024-10-04T02%3A52%3A58Z&se=2025-01-01T02%3A52%3A00Z&sr=b&sp=r&sig=g2%2BQelrF0N%2B6V4c%2FHm6aCl0EgROf6UrvMKU7%2BOqSy%2B4%3D" style="color: #f59f56">HiTeach測驗說明</a>)',
    blobsName:'',//下載檔案名稱
    url:'',//下載檔案連結
    urlOpen:false,//是否提供下載(要判斷兩日前)
    isNow:false,
  },
  {
    step: '4',
    stepStartTime:'1729785600000',//程式判斷時間
    stepEndTime:'1729958400000',//程式判斷時間
    startTime:'113/10/25(五)',
    endTime:'113/10/27(日)',
    stepName: '評審閱卷',
    location:'線上',
    description:'1.主辦單位聘請專家評審針對主觀題進行線上閱卷給分。<br> 2.於113/10/28(一)公布決賽成績。',
    blobsName:'',//下載檔案名稱
    url:'',//下載檔案連結
    urlOpen:false,//是否提供下載(要判斷兩日前)
    isNow:false,
  },
  {
    step: '5',
    stepStartTime:'1731114000000',//程式判斷時間
    stepEndTime:'1731121200000',//程式判斷時間
    startTime:'113/11/09(六) 9:00',
    endTime:'11:00',
    stepName: '班級代表挑戰賽',
    location:'<a target="_blank"  href="https://maps.app.goo.gl/CURDmvmUw1SAjnv36">新竹縣竹北市安興國民小學</a><br>'
      +'活動中心4樓 演藝廳',
    description:'獲選優秀班級可推派代表參加挑戰賽，需由班級教師帶領前往挑戰賽會場。閱讀與評量競賽時間約30分鐘(閱讀兩篇素養文本)。',
    blobsName:'',//下載檔案名稱
    url:'https://teammodel.blob.core.windows.net/event/goodkid/%E5%85%A8%E5%9C%8B%E9%96%B1%E8%AE%80%E5%A5%BD%E5%B0%8F%E5%AD%90%E5%AD%B8%E7%94%9F%E8%82%96%E5%83%8F%E6%8E%88%E6%AC%8A%E6%9B%B8.doc?sv=2023-01-03&st=2024-08-08T09%3A20%3A47Z&se=2024-11-12T09%3A20%3A00Z&sr=b&sp=r&sig=kAKkHT1xvC1e%2F%2FXhOnwWPx4ANKaAiVzo037XCnS9Das%3D',//下載檔案連結
    urlOpen:false,//是否提供下載(要判斷兩日前)
    isNow:false,
  },
  {
    step: '6',
    stepStartTime:'1731121200000',//程式判斷時間
    stepEndTime:'1731124800000',//程式判斷時間
    startTime:'113/11/09(六) 11:00',
    endTime:'12:00',
    stepName: '頒獎典禮',
    location:'<a target="_blank"  href="https://maps.app.goo.gl/CURDmvmUw1SAjnv36">新竹縣竹北市安興國民小學</a><br>'
      +'活動中心4樓 演藝廳',
    description:'頒發優秀學校、班級、個人獎項。',
    blobsName:'',//下載檔案名稱
    url:'',//下載檔案連結
    urlOpen:false,//是否提供下載(要判斷兩日前)
    isNow:false,
  },
]);

const PlanDataRowClass = ({ row }) => {
  if (row.isNow) {
    return 'tableBackground';
  }  
  return '';
};

const Now_plan = ref('');//當前進度名稱
async function getPlanDataFun(){//取得活動資訊
  //取得重要時程資料
  for(let step=0;step<PlanData.length;step++){
    //計算是否是兩天前
    const now = new Date().getTime();
    const stepStartTime = parseInt(PlanData[step].stepStartTime);
    const stepEndTime = parseInt(PlanData[step].stepEndTime);    
    const twoDaysLater = stepStartTime - 2 * 24 * 60 * 60 * 1000;
    PlanData[step].urlOpen = (now >= twoDaysLater) ? true : false;//是否開放下載  
    if(step>=0 && now>=stepStartTime) ActiveStart.value = true; //確認活動是否開始
    if(step>=1 && now>=stepStartTime) PageStatus.value = 2;//狀態改為活動開跑
    if(now>=stepStartTime && now<=stepEndTime){
      PlanData[step].isNow = true;
      Now_plan.value = PlanData[step].stepName;
    }
  }
  // 檢查是否有評審資格  
  let data={jointEventId: ActivityID.value};
  let res = await api.main.getJointEventFind(data);
  if (res && (res.status !== 401) && res.data.length > 0 && res.data[0].groups) {
    let groups = res.data[0].groups;//組別
    for (let i = 0; i < groups.length; i++)    
    {
      if (groups[i].assistants.find(item => item === tmdid.value)) {
        isReviewer.value = true;
        break;
      }
    }
  }
}

// function formatTaiwanDate(timestamp) {//Unix 時間戳轉為"113/09/20 12:00"格式
//   const date = new Date(timestamp);
//   const year = date.getFullYear() - 1911; // 轉換為民國年
//   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份需要加 1 並且補零
//   const day = date.getDate().toString().padStart(2, '0'); // 日期補零
//   const hours = date.getHours().toString().padStart(2, '0'); // 時補零
//   const minutes = date.getMinutes().toString().padStart(2, '0'); // 分補零
//   return `${year}/${month}/${day} ${hours}:${minutes}`;
// }
//重要時程內容↑↑----------------------


//加入班級的的對話框相關資訊↓↓------------------------------------------------------
//參加名單的表格資料↓↓
// let stageTxtArry = ref(['(尚未開始)','(進行中)','(已完成，已具備決賽資格)','(未晉級)']);
// let stageTxtArry = ref(['(進行中)','(進行中)','(已完成)','(未晉級)']);
// let stageTxtArry2 = ref(['(未完成，活動已結束)','(未完成，活動已結束)','(已完成)','(未晉級，活動已結束)']);
let applyInfoData = reactive([//表格資料
  // {
  //   group: '低年級組', groupID:'55edeec3-a3cd-4117-b2e1-4e10c5b3d651',
  //   course: '八年級中階英文',courseID:'e1d817e5-71b3-bc50-52ac-fa828e5f38d6',
  //   class: '2023級10班',  classID:'4dd434a8-a94a-4373-9a05-1a1add533a71',
  //   stage:'班級閱讀熱身賽',  stageID:'',
  //   stageStatus:0,//0未完成,1已完成
  // },
]);
//參加名單的表格資料↑↑

//加入班級資訊↓↓
let signUpGroup = ref([]);//報名組別
const signUpGroupOptions = reactive([//報名組別下拉選單DATA<<顯示用
  // { value: 'Group1', label: '低年級組',GroupId:'',
  //   signUpClassData:[{//塞signUpClassOptions 的格式資料
  //     value: 'grade1',
  //     label: '七年級基礎英文',
  //     id:'',
  //     course: [
  //       { name: '2023級10班',id:'', students: 14, selected: false },
  //       { name: '2023級11班',id:'', students: 18, selected: false },
  //     ],
  //   },
  //   {
  //     value: 'grade2',
  //     label: '八年級中階英文',
  //   },
  // ]},
  // { value: 'Group2', label: '中年級組',GroupId:'',signUpClassData:[]},
  // { value: 'Group3', label: '高年級組',GroupId:'',signUpClassData:[]},
]);
const signUpGroupFun = (value) => {//報名組別下拉選單function
  const group = signUpGroupOptions.find(item => item.value === value.toString());

  // 抓取對應的報名組別的  班級課程/名單(小宇宙) 組  
  signUpClassOptionsShow.splice(0, signUpClassOptionsShow.length);
  group.signUpClassData.forEach(item => {
    signUpClassOptionsShow.push(item);
  });

  //改變報名組別時，就需一併改變名單資料。
  const data = signUpClassOptionsShow.find(item => item.value === signUpClass.value[0]);
  if(data){      
    applyTableData.value = data.course;
  }
}

let signUpClass = ref([]);//班級資訊
let signUpClassOptionsShow  = reactive([]);//班級課程下拉選單data專門顯示用
const signUpClassOptions = reactive([//班級課程下拉選單data
  // {
  //   value: 'grade1',
  //   label: '七年級基礎英文',
  //   id:'',
  //   course: [
  //     { name: '2023級10班',id:'', students: 14, selected: false },
  //     { name: '2023級11班',id:'', students: 18, selected: false },
  //   ],
  // },
  // {
  //   value: 'grade2',
  //   label: '八年級中階英文',
  // },
]);

const signUpClassFun = (value) => {//班級課程下拉選單function
  if(signUpGroup.value.length<=0){
    ElMessage({
      message: '請優先選擇『報名組別』',
      type: 'warning',
    })
  }else{    
    const group = signUpGroupOptions.find(item => item.value === signUpGroup.value[0]);
    if(group){
      let signUpClassOptions = group.signUpClassData;
      const data = signUpClassOptions.find(item => item.value === value.toString());
      if(data){      
        applyTableData.value = data.course;
      }
    }
  }
}

async function getActiveData(){//取得統測教師課程及組別
  if(!ActivityID.value && !tmdid.value)return;
  //取得活動組別資料
  const groupName = 'Group';
  let runCount = 0;  
  for(let element of groupListInfo){//<<<須改  groupListInfo
    runCount++;
    const signUpClassData = JSON.parse(JSON.stringify(signUpClassOptions));
    // if(isTestWeb.value)signUpGroupOptions.push({value:groupName+runCount,label:element.name,GroupId:element.testID,signUpClassData:signUpClassData});
    // else signUpGroupOptions.push({value:groupName+runCount,label:element.name,GroupId:element.OfficialID,signUpClassData:signUpClassData});
    signUpGroupOptions.push({value:groupName+runCount,label:element.name,GroupId:element.OfficialID,signUpClassData:signUpClassData});        
  }

  //取得統測老師資料
  let data={jointEventId:ActivityID.value,creatorId: tmdid.value};
  let JointCourseData = await api.main.getJointCourseFind(data); 
  if(JointCourseData && JointCourseData.data.length!==0){//若有報名資料，則繼續往下處理
    isJoin.value = true;//有報名
    if(PageStatus.value===0)PageStatus.value = 1;//改到已經報名的階段
    JointCourseData = JointCourseData.data;
    JointCourseData.forEach(res => {
      signUpGroupOptions.forEach(group => {//確認報名資訊，並修改報名勾選資料and顯示報名組別的資料
        let Data_group = '';
        let Data_groupID = '';
        let Data_course = '';  
        let Data_courseID = '';    
        let Data_class = '';
        let Data_classID = '';
        if (group.GroupId === res.jointGroupId ) {
          Data_group = group.label;
          Data_groupID = group.GroupId;
          group.signUpClassData.forEach(classData => {
            res.courseLists.forEach(course => {
              if (classData.id === course.courseId) {
                Data_course = classData.label;
                Data_courseID = classData.id;
                course.groupLists.forEach(groupList => {
                  classData.course.forEach(courseDetail => {
                    if (courseDetail.id === groupList.id) {
                      Data_class = courseDetail.name;
                      Data_classID = courseDetail.id;
                      courseDetail.selected = true;
                      let stageData = '';
                      if(Now_plan.value!=='')stageData = groupList.schedule.find(item => item.name === Now_plan.value);
                      else {//假設時間在數據期間，則顯示上一筆。
                        let planData = getCurrentPlanData();
                        stageData = groupList.schedule.find(item => item.name === planData.stepName);
                      }
                      applyInfoData.push({//加入報名班級資訊
                        group: Data_group , groupID:Data_groupID,
                        course: Data_course ,courseID:Data_courseID,
                        class: Data_class,  classID:Data_classID,
                        stage: (stageData!=''? stageData.name : ''), stageID:(stageData!='' ? stageData.id: ''),
                        stageStatus:stageData.status,//進行狀況 "undo" "doing" "complete" "disqualify" //
                      });
                    }
                  });
                });
              }
            });
          });
        }
      });
    });
  }
}

function getCurrentPlanData() {
  const now = new Date().getTime(); // 當前時間戳
  let currentPlan = PlanData[PlanData.length - 1]; // 預設為最後一筆資料

  for (let i = 0; i < PlanData.length; i++) {
    const stepStartTime = parseInt(PlanData[i].stepStartTime); // 開始時間
    const stepEndTime = parseInt(PlanData[i].stepEndTime); // 結束時間

    // 判斷當前時間是否介於這筆資料的開始和結束時間之間
    if (now >= stepStartTime && now <= stepEndTime) {
      currentPlan = PlanData[i];
      break; // 找到符合條件的資料，跳出循環
    } else if (i > 0 && now < stepStartTime) {
      // 如果超過上一筆資料結束時間且在這筆資料開始時間之前，顯示上一筆資料
      currentPlan = PlanData[i - 1];
      break;
    }
  }
  return currentPlan; // 回傳當前符合條件的計劃資料
}

const applyTableData = ref([]);
async function getIes5dataFun(){//取得老師課程的相關資料
  let data={tmid: tmdid.value};
  let res = await api.main.getIesTeacherCourse(data);
  if(res && (res.status === 200))res = res.data;
  const gradeName = 'Grade';
  let runCount = 0;
  for(let element of res){//接『班級課程』資料
    runCount++;
    scope.value = element.courseBase.scope;//個人/學校 "private":個人 "school":學校
    let course = reactive([]);
    if(element.courseTasks.length>0){      
      for(let data of element.courseTasks[0].schedules){//接『名單』資料
        console.log('data='+data);
        course.push({name:data.groupName,id:data.groupId,students:data.memberCount ,selected: false});       
      }       
    }
    signUpClassOptions.push({value:gradeName+runCount,label:element.courseBase.name,id:element.courseBase.id,course:course}); 
  }
}

//更新資料
async function updateDataFun(){
  console.log('updateDataFun');
  signUpClass.value = [];//清空下拉組別
  applyTableData.value = [];//清空下拉班級課程

  //重新取得活動組別資料
  signUpClassOptions.length = 0;//清空
  await getIes5dataFun();//取得老師課程的IES資料
  signUpGroupOptions.length = 0;//清空
  const groupName = 'Group';
  let runCount = 0;  
  for(let element of groupListInfo){
    runCount++;
    const signUpClassData = JSON.parse(JSON.stringify(signUpClassOptions));
    if(isTestWeb.value)signUpGroupOptions.push({value:groupName+runCount,label:element.name,GroupId:element.testID,signUpClassData:signUpClassData});
    else signUpGroupOptions.push({value:groupName+runCount,label:element.name,GroupId:element.OfficialID,signUpClassData:signUpClassData});
  }
  if(signUpGroup.value.length>0)signUpGroupFun(signUpGroup.value);  
}

function dialogAddClassCancel(){//取消
  dialogAddClassCtrl.value = false;
  signUpClass.value = [];//清空下拉組別
  applyTableData.value = [];//清空下拉班級課程
}
async function dialogAddClassConfirm(){//確認 
  //確認名單至少選一個
  const chooseCount = countSelectedItems(signUpGroupOptions);  
  if(!chooseCount){//如果沒有選擇任何名單
    ElMessage({
      type: 'error',
      message: '請選擇『名單』！',
    });
    return;
  }
  dialogAddClassCtrl.value = false;//關閉對話框
  classListCtrl.value = true;//開啟報名班級的顯示
  if(PageStatus.value===0 && !isJoin.value){//如果在報名階段 
    PageStatus.value = 1;//成為已報名的階段
    isJoin.value = true;//已經報名
    //寄送EMAIL
    let sentEmailData = {
      type:"join", //寄送類型 ※join:報名成功  
      mail:ecEmail.value, //ecEmail.value 'ruby29922026@gmail.com'
      name:ecName.value
    }
    await api.main.ActivitySentEmail(sentEmailData);

    let ApplyCouponData = {
      id_token:sessionStorage.getItem("id_token"),
      coupon:"GOODKID2024"
    }
    await post(coreAPIUrl + '/oauth2/ApplyCoupon', ApplyCouponData).then(async res => {
      if(res && res.message){
        ElMessage({
          message: "已經報名過該活動",
          type: 'warning',
        })
      }
    });
  }

  //將老師報名資料回報到資料庫
  // applyInfoData.length = 0;//班級更新清空
  let applyInfoDataTemp = reactive([]);//報名班級暫存
  for(let element of signUpGroupOptions){
    const courseLists = element.signUpClassData.reduce((acc, classData) => {
      const selectedCourses = classData.course.filter(course => course.selected);
      if (selectedCourses.length > 0) {
        selectedCourses.forEach(course => {
          acc.push({
            subjectId: null, //科目ID ※預留給學校班級用，個人課程記入null
            courseId: classData.id, //課程ID
            courseName: classData.label, //課程名稱
            groupLists: [{
              id: course.id, //課程名單ID
              name: course.name //課程名單名稱
            }]
          });
        });
      }
      return acc;
    }, []);
    const isFind = applyInfoData.find(item => item.groupID === element.GroupId);
    if(courseLists.length>0 || isFind){
      let data={
        jointEventId:ActivityID.value,//活動ID
        jointGroupId:element.GroupId,//組別ID
        creatorId: tmdid.value,//創建者(報名者)TMID
        creatorName :ecName.value,  //創建者名
        creatorEmail:ecEmail.value,  //創建者Email
        schoolId: sessionStorage.getItem("shortCode"), //[非必須] 學校簡碼 ※無值會記入空字串
        schoolName: sessionStorage.getItem("school_name"), //[非必須] 學校名稱 ※無值會記入空字串
        countryId: sessionStorage.getItem("countryId"),
        countryName: sessionStorage.getItem("countryName"),
        provinceId:null,
        provinceName:null,
        cityId: sessionStorage.getItem("cityId"),
        cityName: sessionStorage.getItem("region_name"),
        scope :scope.value,//個人/學校 "private":個人 "school":學校
        courseLists:courseLists,//課程列表與內部課程資訊
      };
      let JointCourseData = await api.main.setJointCourseUpsert(data); 
      
      //更新報名班級顯示
      if(courseLists.length>0){
        for(let CourseData of JointCourseData.jointCourse.courseLists){
          for(let classData of CourseData.groupLists){
            applyInfoDataTemp.push({
              group: element.label , groupID:element.GroupId,
              course: CourseData.courseName ,courseID : CourseData.courseId,
              class: classData.name,  classID: classData.id,
          //   stage:'班級閱讀熱身賽',  stageID:'',
          //   stageStatus:0,//0未完成,1已完成
            });
          }
        }
      }
    }
  }
  applyInfoData.splice(0, applyInfoData.length, ...applyInfoDataTemp);//更新報名班級顯示。
  console.log("applyInfoDataTemp="+applyInfoDataTemp);
}

const countSelectedItems = (options) => {//只要有任一個 signUpGroupOptions的 selected = true就回傳true
  return options.some(group => 
    group.signUpClassData.some(classData =>
      classData.course.some(course => course.selected === true)
    )
  );
};

//抓資料看是否是手機
function isMobileBrowser(){
  isPC.value =  sessionStorage.getItem("isPC") === 'true';
}

//加入班級的的對話框相關資訊↑↑------------------------------------------------------



//路由器↓↓
// const routers = useRouter();
// function goToUrl(url){
//   routers.push(url);
// }
//路由器↑↑

//偵測寬度
const isNarrow = ref(false);
const handleResize = () => {
  isNarrow.value = window.innerWidth < 768;
};


onMounted(async () => {    
  window.addEventListener('resize', handleResize);
  handleResize(); // 初始检查窗口宽度
  isMobileBrowser();
  window.addEventListener('resize', isMobileBrowser);

  //判斷stageTxtArry顯示字串
  // const now = new Date().getTime(); // 當前時間戳
  // if(now > 1729764000000)stageTxtArry.value = stageTxtArry2.value;

  if (sessionStorage.getItem("id_token")) {// 檢查有無登入資料,並拿tmdid
    let t_data = jwtDecode(sessionStorage.getItem("id_token"));
    tmdid.value = t_data.sub; 
    ecName.value = sessionStorage.getItem("open_name");
    ecEmail.value = sessionStorage.getItem("open_mail");

    //判斷是否可以看決賽地點。
    FractionData.forEach(element => {
      element.data.forEach(temp => {
        if(tmdid.value === temp.tmid)localRead.value = true;
      });
    });

    //判斷活動ID
    let websitKeyWord = window.location.host;
    let isMatch = testWebList.value.some(keyword => websitKeyWord.includes(keyword));
    if(isMatch) {
      isTestWeb.value = true;
      // ActivityID.value = webID.test;//給測試站ID
      ActivityID.value = webID.Official;//以外為正式站ID
    }
    else ActivityID.value = webID.Official;//以外為正式站ID

    await getIes5dataFun();//取得老師課程的IES資料[優先於getPlanDataFun()，因為 signUpClassOptions 要先拿，下方資料才能塞]
    await getPlanDataFun();//取得活動的相關資料
    await getActiveData();//取得統測教師課程及組別(需在getPlanDataFun()之後，因為需要signUpGroupOptions的資料)
    
  }else await getPlanDataFun();//取得活動的相關資料
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
  window.removeEventListener('resize', isMobileBrowser);
});


//點選按鈕的function↓↓
function BtnFunction(val){
  //先判斷有無登入
  if(ActiveStart.value){
    if (sessionStorage.getItem("open_name")) {
      //PageStatus 0我要報名，1編輯班級，2報名成功
      if ((ActiveStart.value && (val === 'join' && PageStatus.value === 0)) || (val === 'edit' && PageStatus.value === 1)) {
        dialogAddClassCtrl.value = true;      
      }
    } else {
      // 統購=>info=1 活動=>info=2  如果是從活動頁進來的  紀錄一下 以便完成綁定之後直接到活動頁
      if(location.pathname === "/Activity_Info"){
      sessionStorage.setItem("info", "2");
    }else if(location.pathname === "/goodkid"){
      sessionStorage.setItem("info", "3");
    }        
      let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
      let data = {
         client_id: profile.clientID["global"],
         op_redirecturi: "https://" + urlhost + "/Binding_CheckAccount"
      }
      let datastr = encodeURIComponent(Base64.encode(JSON.stringify(data)));
      // let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
      // let url = "https://oidc.tanet.edu.tw/onesteplogin?Auth_Request_RedirectUri=https://account.teammodel.net/quickopencode/educloudtw/" + profile.clientID["global"] + "?op_redirecturi=" + Base64.encode("https://" + urlhost + "/Binding_CheckAccount") + "&Auth_Request_State=educloudtw&Auth_Request_Response_Type=code&Auth_Request_Client_ID=c58722f58275a8c81fcc5ed6b94f3e7b&Auth_Request_Nonce=aaaaa&Auth_Request_Scope=openid+email+profile+eduinfo&local=true";
      let url = "https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=https://account.teammodel.net/&scope=openid+email+profile+eduinfo&state="+datastr+"&nonce=aaaaa";
      location.href = url;
    }
  }else{
    // ElMessageBox.alert('113/09/09(一)12:00起 開放報名！', '通知', {
    //   confirmButtonText: 'OK',
    // });
  }
}
// function DownLoadFun(url){//簡章下載程式碼  
//   console.log('DownLoadFun簡章下載function');
//   const link = document.createElement('a');
//   link.href = url;
//   debugger
//   link.download = url.substring(url.lastIndexOf('/') + 1); // 可以設定為你希望的文件名
//   link.click();
// }
//點選按鈕的function↑↑


//跳轉IES5
function gotoTeammodel(target){
  if (tmdid.value !== "" && sessionStorage.getItem("id_token")) {
    // 先用帳密取id_token
    let data = {
      grant_type: "code",
      client_id: clientID,
      nonce: "0",
      id_token: sessionStorage.getItem("id_token")       
    }
    post(coreAPIUrl + '/oauth2/login', data).then(async res => {
      if (res && res.code) {
        let urlhost = api.main.geties5ApiUrl();        

        let websirtPart2 = urlhost + '/login/?code=' + res.code + '&identity=teacher&target=' + target;
        window.open(websirtPart2, '_blank');
      } 
    })
  } else {
    ElMessage({
      type: 'error',
      message: t('AccountLogin.enterAccountAndPassword'),
    })
  }
}

function getfileUrl(){
  return location.origin +  "/files/" + encodeURI("全國好小子熱身練習試卷.htex")
}

</script>

<style lang="less" scoped>
@fontColor :#424f55;//文字：深藍
@fontColor-light :#8da3a0 ;//文字：中藍
@stressColor :#f59f56;//強調色：橘色
@fontColor-gray1: #909399;
@fontColor-gray2: #b1b3b8;
@other-blue:#70a6b2;//其他輔助色：水藍
@background-gray:#eff4f7;//背景：淡灰(footer顏色)

.redColor{
  color:red;
}

.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.div-center2{
  margin-right: auto;
  margin-left: auto;
}
.div-centerY{ /*垂直置中*/
  display: flex;    
  align-items: center;
  flex-direction: column;  //有時可能需要在加這個屬性
  &.YUnset{
    flex-direction: unset;  
  }
}
.div-centerY2{ /*垂直置中*/
  margin-top: auto;
  margin-bottom: auto;
}
.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
}
.between{ /*子元素分左右兩邊*/
  display: flex;
  justify-content: space-between;
}
.cursorPointer{
  cursor: pointer;//滑鼠變成小手
}


.home-page {
  position: relative;//父元素設置為相對定位
  height: unset;
  padding: unset !important;

  .PageBack{
    color:@fontColor-gray1;
  }
  .backDiv{
    .backTxt{
      margin-right: 20px;
      font-weight:bold;
    }
    .backImg{
      width: 50px;
    }
  } 
  .inputEmailDiv{
    margin-bottom: 30px;
  }
  .joinButtonArea{
    margin-top: 30px;
    .joinButton{
      align-items: center;
      border-radius: 50px;//圓角
      font-size: 20px;
      font-weight:bold;
      width: 200px;
      height: 50px;
      background: @fontColor-gray1;
      cursor: no-drop;//滑鼠變成禁止
      &.downLoad{
        background-color: @other-blue;        
        &.load{
          cursor: pointer;//滑鼠變成小手
        }
        &.PC{
          margin-right: 20px;
        }
      }
      &.mar-right{        
        &.PC{
          margin-right: 20px;
        }
      }
      &.onClick{
        background: @stressColor;
        cursor: pointer;//滑鼠變成小手
      }
    }
  }
  .VideoDiv{
    margin-top: 30px;
    .Video{
      // width:560px;
      // height:315px;
      width:1000px;
      height:580px;
    }
    .VideoPhone{
      width: 400px;
      height: 232px;
    }
  }
  .signDataButtonDiv{
    padding-bottom: 100px;
  } 
  .picButton{//圖片按鈕    
    position: relative;
    cursor: pointer;//滑鼠變成小手   
    margin-top: 10px;
    width: 100px;
    height: 35px;
    &.big{
      width: 200px;
      height: 50px;
    }
    .buttonImg{
      height: 100%;    
      width: 100%;  
      display: block;   
    }
    .pngText{
      // position: absolute;
      // margin-left: auto;
      font-size:16px;
      &.big{
        font-size: 20px;
      }
      
      transform: translate(0%, -130%);
      color: white;
      font-weight:bold;
      text-align: end;
    }
    .txt{
      font-size: 20px;
      font-weight:bold;
    }
  }
  .ActiveAllInfo{
    position: relative;
    margin-top: 10px;
    .ActiveTitle{
      // font-weight:bold;//粗體
      font-size: 70px;
      margin-bottom: 10px;
      &.PC{
        font-size: 50px;
      }
    } 
    .ActiveInfoDiv{
      // width: 90%;
      margin-top: 10px;
      @media (max-width: 1250px) {
        display: flex;    
        align-items: center;
        flex-direction: column;
      }
      .ActivePic{
        width: 250px;
        height: 250px;
        .pic{
          width: 100%;
          height: 100%;
          // object-fit: cover;
          object-fit: contain;//確保整張圖被看見
        }
      }  
      .titleContentArea{
        margin-left: 20px;
        // .ActiveTitle{
        //   font-weight:bold;//粗體
        //   font-size: 30px;
        //   margin-bottom: 10px;
        // }  
        .otherInfoArea{
          flex: 1;        
          min-width: 250px;
          max-width: 300px;
          margin-bottom: 20px;
          .Replenish{
            color:@fontColor-gray1;
            font-style:oblique;
          }
          @media (max-width: 1090px) {
            margin: 50px 20px;
          }
        }  
      }
    }  
  }
  .cotentDiv{
    width: 80%;  
    max-width: 1200px;
    display: flex;    
    align-items: center;
    flex-direction: column;
    @media (max-width: 1250px) {
      width: 90%;  
    }
    ::v-deep .tableBackground{
      background-color: #f5a05620;
    }
    ::v-deep .current_progress{
      color: red;
      font-size: 12px; /* 調整文字大小 */
    }
    .tableDiv{   
      width: 90%;
      max-width: 1200px;
      margin-top: 50px;
      // height: 45vh; 
      .info{
        display: flex;
        align-items: end;
      }  
      &.class{
        margin-bottom: 50px;
      }
      &.planDiv{
        margin-bottom: 100px;
      }
      ::v-deep .info-cell {
        white-space: normal; /* 允许折行 */
        word-wrap: break-word; /* 折行 */
        text-align: left; /* 左对齐 */
      }
      .titleDiv{
        margin-bottom: 10px;
        .title{
          font-weight:bold;
          font-size: 25px;
          text-decoration: underline;
          margin-top: auto;
          .iconPic{
            width: 50px;
            margin-right: 10px;
          }          
        }
      }  
      
      .winCotent{
        width: 100%;
        margin-bottom: 30px;
        .ContestTitle{
          font-weight:bold;//粗體
          font-size: 20px;
          margin-bottom: 10px;
          margin-top: 10px;
          color: @other-blue;
          margin-right: auto;
          align-items: center;   
          &.isPc{
            margin-left: 12px;
          }       
          .titleIconImg{
            width: 40px;
            margin-right: 10px;
          }
        }
        .tableCss{//電腦版CSS
          width: 100%;
          align-items: center;
          .iconPic{
            width: 30px;
            margin-right: 10px;
          }
          .winTxt{
            display: flex;    
            align-items: center;
          }
        }
        .tableCssPhone{//手機板CSS
          width: 100%;
          .Div1{
            .iconPic{
              width: 30px;
              height: 30px;
            }
            .winTxt{
              display: flex;    
              align-items: center;
            }
          }
          .Div2{
            width: 180px;
            .left{
              display: flex;
              justify-content: left;
            }
            .rightSet{
              margin-right: 10px;
            }
          }
          .line{
            border: 1px dashed @background-gray;
            width: 90%;
            margin: 10px auto;
          }
        }
      }
      .QA_Content{
        text-align: left;
        .step{
          color: #8da3a0;
        }
        .list{
          margin-bottom: 10px;
          a{
            color: #57a3f3;
          }
        }
        .picShow{
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      }
      .schoolList{
        color: #70a6b2;
        font-size: 20px;
        .list{
          display: flex;
          justify-content: left;
          margin-bottom: 10px;
          .iconPic{
            width: 30px;
            margin: 0 10px;
          }
        }
      }
      .movie_Content{
        .pc{
          iframe{
            width: 45%;
            min-width: 450px;
            margin: 15px 10px;
            height: 250px;
          }
        }
        .phone{
          iframe{
            margin-bottom:10px;
          }          
        }
      }
      .PhoneArea{
        margin-top: 20px;
        .title{
          font-size: 15px;
          font-weight:bold;//粗體
        }
        .left{
          display: flex; 
          justify-content: start; 
        }
        .line{
          margin: 20px 0 ;
          width: 100%;
          height: 0;
          border-top: 2px dashed var(--el-border-color);
          // border-top: 1px solid var(--el-border-color);
        }
      }
      .tableArea{
        // height: 400px;
      }
      // @media (min-width: 1170px) {
        
      .winArea{
        .winBtn{
          border-radius: 50px;
          height: 30px;
          border: 2px solid @stressColor;
          color: @stressColor;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
        .winBtn:hover{
          background: @stressColor;
          color: white;
        }
      }  
      // }    
      &.left{
        // @media (min-width: 1170px) {
        //   width: 50%;   
        //   min-width: 450px;
        //   max-width: 550px;
        //   // height: 45vh;
        // }
        // @media (max-width: 1170px) {
        // }
      }
      &.right{         
        // @media (min-width: 1170px) {
        //   flex: 1;
        //   min-width: 380px;
        //   max-width: 500px;
        //   margin-left: 100px;
        // }
        // @media (max-width: 1170px) {
          // margin-top: 30px;
          margin-left: 0px;
        // }
      }
    }
  }
  ::v-deep .el-dialog__body{
    width: 100%;
  }
  ::v-deep .applyTableDiv{
    @media (max-width: 750px) {
      width: 90% !important;
    }
    .content{
      max-width: 700px;
      margin-top: 10px;
      margin-bottom: 20px;
      .info{
        margin-bottom: 20px;
        .urlCss{
          color: #409EFF;
        }
      }
      .chooseArea{
        margin-bottom:20px;
        margin-right: 10px;
        display: flex;
        // ::v-deep .signUpChooseDiv{
        //   flex: 1;
        // }
      }
      .signUpTableDiv{
        width: 500px;
        margin-bottom: 20px;
      }
      .dialogFooter{
        margin-top: 20px;
      }
    }
  }
  .scheduleArea{
    .titleArea{
      margin: 0 10px;
      .titleSet{
        align-items: baseline;
        .stepName{
          font-size: 20px;
          font-weight:bold;//粗體
        }
        .current_progress{
          margin-left: 10px;
        }
      }
    }
    .card{
      padding: 15px 20px;
      margin-bottom: 20px;
      background: #F6EDE4;
      border-radius: 20px;//圓角 
      .place{
        font-weight: bold;
      }
      .time{
        color:@fontColor-light;
        font-style: oblique;
      }
      .description{
        // display: flex;
        text-align: start;
        margin-top: 10px;
      }
    }
    .triangleArea{
      margin-bottom: 20px;
      
      transform: scale(0.7);
      .triangle{
        width: 0;
        height: 0;
        border-style: solid;        
        border-width: 25px 25px 0 25px;
        &.small{
          transform: scale(0.6);
        }
        border-color: #8da3a0 transparent transparent transparent;
      }
    }
  }
  .footerDiv{
    background-color: @background-gray;
    padding:20px ;
    margin: unset;
    .txtDiv{
      text-align: left;
      width: 80%;
    }
    &.phone{
      padding: 20px 30px;
    }
  }
}
</style>
