<template>
  <div class="home-page div-center div-centerY" v-loading="loadingCtrl">
    <!-- 選擇登入模式 -->
    <div class="div-center div-centerY" v-show="!tmdidLogin">
    <div class="explain">{{$t('AccountLogin.chooseMethodToProceed')}}</div>    
    <div class="oneLine buttonGroup">
      <div>
        <div class="button"  @click="goLoginTmdid(1)">
          <img class="buttomImg" src="../../assets/img/area-08.png" alt="">
            <div class="pngText">
              {{$t('AccountLogin.loginWithTeamModelAccount')}}
              <br/>&nbsp;                            
            </div>
        </div>        
      </div> 
      <div>
        <div class="button" @click="gologin">
          <img class="buttomImg" src="../../assets/img/area-01.png" alt="">
            <div class="pngText" >
              {{$t('AccountLogin.loginWithEduCloud')}}<br/>
              &nbsp;
            </div>
        </div>        
      </div>
    </div>
    <img class="beanPic" src="../../assets/img/bean/bean_07.png" alt="">
    <div class="backDiv" @click="goback()">{{$t('AccountLogin.back')}}</div>
    </div>
    <!-- 醍摩豆登入模式 -->
    <!-- <div class="div-center div-centerY wayArea" v-show="tmdidLogin">
      <div class="title">{{$t('AccountLogin.emailPhoneUserID')}}</div>
        <el-input class="input" v-model="teamModel.id" style="width: 240px" :placeholder="$t('AccountLogin.emailPhoneUserID')"  />
        <el-input class="input" v-model="teamModel.password" style="width: 240px" :placeholder="$t('AccountLogin.password')" />
        <div class="imgBtn div-center2" style="cursor: pointer;"  @click="loginTmdid()">
          <div class="txt">{{$t('AccountLogin.login')}}</div>
          <img src="../../assets/img/area-08.png">
        </div>
        <div v-show="isAllLogin" style="margin-top: 20px;cursor: pointer;"  @click="goLoginTmdid(0)">{{$t('AccountLogin.back')}}</div>
    </div>     -->
  </div>
</template>

<script setup>
import { ref, onMounted  } from 'vue'//,ref,reactive
//import { ElMessage } from 'element-plus';
//import { onMounted, ref } from 'vue'
import { useRouter, useRoute  } from 'vue-router'
//import { post } from "@/api/http";
import profile from "../../api/profile";
//import option_gl from '@/static/regions/region_gl.json'
//const optionsData = option_gl[0].children
import { useGlobalMethods } from '@/utils/useGlobalMethods';
import { Base64 } from 'js-base64';
import api from "@/api";
//import jwtDecode from 'jwt-decode';

const { afterLoginRouter } = useGlobalMethods();
//路由
const route  = useRoute()
const routers = useRouter();
//const serverLocation = "global";
//const clientID = profile.clientID[serverLocation];
//const coreAPIUrl = profile.apiUrl[serverLocation];
//const gt_educloudtw = "educloudtw";
//const teamModel = reactive({id:'',password:''});// 醍摩豆帳號綁定


let loadingCtrl = ref(false);//載入畫面控制
//let tmdidLogin = ref(false);//醍摩豆登入畫面控制
let isAllLogin = ref(false);//醍摩豆登入返回鍵控制


//const t = inject('$t');//三國語系

//字串組
// const haveTeamModelAccount = computed(() =>t('AccountLogin.haveTeamModelAccount').split(','));
// const noTeamModelAccount = computed(() =>t('AccountLogin.noTeamModelAccount').split(','));

checkLoginType();

onMounted(() => {  
});



// 檢查帳號狀態
async function checkLoginType() {
  if(route.query.eid){
    let data = { jointEventId: decodeURIComponent(Base64.decode(route.query.eid)) };    
    let res = await api.main.getEventData(data);
    if (res.errCode === "") {
      let blobEventData = JSON.parse(res.jointEvent)
      if(blobEventData.loginType.includes("tmdId") && blobEventData.loginType.includes("eduId")){
        isAllLogin.value = true               
      }else if(blobEventData.loginType.includes("tmdId") && !blobEventData.loginType.includes("eduId")){
        goLoginTmdid()
        //tmdidLogin.value = true
      }
    }
    else {
      console.error(res.error);
    }    

  }else{
    routers.push("/");
  }
 console.log("確認登入類型")
 
}

function goback(){//返回
  afterLoginRouter();
}


//教育雲登入
function gologin() {
  //sessionStorage.setItem("info", "202501");    
  let urlhost = location.host == "localhost:5005" ? "testHT.teammodel.net" : location.host
  let data = {
     client_id: profile.clientID["global"],
     op_redirecturi: "https://" + urlhost + "/Binding_CheckAccount"
  }
  let datastr = encodeURIComponent(Base64.encode(JSON.stringify(data)));
  
  //"https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=https://account.teammodel.net/&scope=openid+email+profile+eduinfo&state=[編碼結果]&nonce=aaaaa"
  // let url = "https://oidc.tanet.edu.tw/onesteplogin?Auth_Request_RedirectUri=https://account.teammodel.net/quickopencode/educloudtw/" 
  //+ profile.clientID["global"] + "?op_redirecturi=" + Base64.encode("https://" + urlhost + "/Binding_CheckAccount") + "&Auth_Request_State=educloudtw&Auth_Request_Response_Type=code&Auth_Request_Client_ID=c58722f58275a8c81fcc5ed6b94f3e7b&Auth_Request_Nonce=aaaaa&Auth_Request_Scope=openid+email+profile+eduinfo&local=true";
  let url = "https://oidc.tanet.edu.tw/oidc/v1/azp?response_type=code&client_id=c58722f58275a8c81fcc5ed6b94f3e7b&redirect_uri=https://account.teammodel.net/&scope=openid+email+profile+eduinfo&state="+datastr+"&nonce=aaaaa";
  location.href = url;
}
function goLoginTmdid(){

  api.main.loginTmdID();
  // if(flag === 1){
  //   tmdidLogin.value = true
  // }else{
  //   tmdidLogin.value = false
  // }
}
// 醍魔豆帳號登入
// function loginTmdid() {
//   if (teamModel.id !== "" && teamModel.password !== "") {
//     // 先用帳密取id_token
//     let data =
//     {
//       grant_type: "account",
//       client_id: clientID,
//       nonce: "0",      
//       lang: "zh-tw",      
//       account: teamModel.id,
//       password: teamModel.password
//     }
//     post(coreAPIUrl + '/oauth2/login', data).then(async res => {      
//       if (res.error) {
//         ElMessage({
//           type: 'error',
//           message: t('AccountLogin.incorrectAccountOrPassword'),
//         })
//       } else {
//         let t_data = jwtDecode(res.id_token);
//          sessionStorage.setItem("access_token", res.access_token)
//          sessionStorage.setItem("id_token", res.id_token)
//          sessionStorage.setItem("open_name", t_data.name)
//         //  sessionStorage.setItem("edu_id_token", res.edu_id_token)
//         //  sessionStorage.setItem("open_mail", res.mail)
//         routers.push("/" + sessionStorage.getItem("info"))
//         //routers.push("/")
//         console.log(t_data);
//       }
//     })
//   } else {
//     ElMessage({
//       type: 'error',
//       message: t('AccountLogin.enterAccountAndPassword'),
//     })

//   }
// }


</script>

<style lang="less" scoped>
@fontColor :#424f55;//文字：深藍
@fontColor-light :#8da3a0;//文字：中藍
@stressColor :#f59f56;//強調色：橘色
@fontColor-gray1: #909399;
@fontColor-gray2: #b1b3b8;
@background-yellow:#F6EDE4;//背景：淡黃

.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
}
.div-centerY{ /*垂直置中*/
  display: flex;    
  align-items: center;
}
.home-page{
  margin-top: 50px;
  .explain{
    font-size: 20px;
    font-weight:bold;    
  }
  .timeInfo{
    color: @fontColor-light;
    margin-top: 10px;
    font-style:oblique;
  }
  .buttonGroup{
    margin: 30px 0px;
    .button{
      margin: 0px 50px;
      position: relative;
      cursor: pointer;//滑鼠變成小手
      .buttomImg{
        width: 300px;
        display: block;      
      }
      .pngText{
        position: absolute;
        transform: translate(0%, -140%);
        color: white;
        font-size: 30px;
        width: 100%;
        font-weight:bold;
        margin-top: 20px;
      }
    }
    .info{
      color:@fontColor-gray1;
      margin: 20px 0 40px 0;;
    }
  }
  .beanPic{
    height: 150px;
  }
  .backDiv{
    margin-top: 30px;
    color: @fontColor ;
    cursor: pointer;//滑鼠變成小手
    margin-bottom: 70px;
  }
  .wayArea{
      background: @background-yellow;
      padding: 30px;
      margin: 10px;
      width: 320px;
      border-radius: 40px;
      
      .qr-code-container {
        position: relative;
        display: inline-block;
        .Img{
          display: block;
          width: 180px;
          margin-top: 20px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.95); /* 白色半透明背景 */
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 10px;
          box-sizing: border-box;
          color: red; 
        }
      }
      .title{
        margin-bottom: 10px;
      }
      .input{
        margin: 10px 0;
        height: 50px;
      }
      .txtInfo{
        margin-top: 10px;
        font-size: 14px;
      }
      .MsgDiv{
        width: 100%;
        .phonetxt{
          flex: 1;
        }
        .sentCode{
          font-size: 14px;
          margin: 10px 0;
          font-weight:bold;            
          &.sent{
            width: 50px;
            color: #409EFF;
            cursor: pointer;//滑鼠變成小手
          }
          &.wait{
            width: 60px;
            color: @fontColor-gray2;
          }        
        }
      }
      
      .imgBtn{
        margin-top: 10px;
        position: relative;
        width:140px;
        height: 40px;
        margin-left: auto;
        font-size: 14px;
        //cursor: pointer;//滑鼠變成小手
        .txt {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white; 
          text-align: center;
        }
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
}
</style>
