import mindmap from "./mindmap";
import konvaCanvas from "./konvaCanvas";
export default {
  konvaCanvas,
  mindmap,
  "header": {
    "digitalLearningOffice": "{region}政府教育局",
    "authorizationPlatform": "HiTeach 授權與活動平台",
    "cityInformation": "{region}資訊",
    "softwareIntroduction": "HiTeach軟體介紹",
    "learningResources": "學習資源",
    "latestNews": "最新消息",
    "contactUs": "聯絡我們",
    "login": "登入"
  },
  "eventPage": {
    applyCheck:'我要申請/查詢',
    authorization:'授權',
    entry1:'縣市統一採購HiTeach授權進入口',
    getAuthorization:'由此進入後申請授權',
    check:'我要報名/查詢',
    event:'活動',
    competition:'縣市XHiTeach競賽活動',
    registration:'由此進入後申請報名',
    eventPreparation: "活動籌備中",
    notification: "通知"
  },
  "AccountInfo": {
    "availableProducts": "可申請商品",
    "noAvailableItems": "目前沒有可申請的項目",
    "apply": "申請",
    "authorizationNote": "※如果HiTeach日期重複，重疊時間會往後累加！",
    "myAuthorizations": "我的授權",
    "customerSupport": "客服協助",
    "productName": "產品名稱",
    "usageTime": "使用時間",
    "state": "狀態",
    additionalItem: '附加項',
    "confirmApplication": "確定要申請嗎？",
    "inquiry": "詢問",
    "yes": "對",
    teamModelAccount: '醍摩豆用戶編號',
    schoolNotInList: '學校不在此次的統購清單中',
    schoolAuthorizationFull: '學校授權已滿',
    clickToLearnHiTeach: '還不會使用HiTeach嗎？,請點這學習!',
    notApplied: '未申請',
    appliedNotAuthorized: '申請已受理'
  },
  "AccountLogin":{
    sendVerificationCode: '傳送認證碼',
    resend: '重新發送',
    haveTeamModelAccount: '我有,醍摩豆帳號',
    noTeamModelAccount: "我沒有,醍摩豆帳號",
    chooseMethodToProceed: '請選擇任一種方式繼續下一步',
    bindExistingTeamModelAccount: '進入綁定原有醍摩豆帳號頁面',
    registerAndBindNewAccount: '新註冊醍摩豆帳號，並綁定教育雲帳號',
    completeWithin30Minutes: '此階段請於{time}分鐘內完成，如逾期將須重新登入',
    back: '返回',
    chooseBindingMethod: '請選擇,任一種,方式綁定原有醍摩豆帳號',
    qrCodeBinding: 'QR-Code',
    qrCodeExpired: '您的QR Code已失效，請點選下方更新按鈕',
    update: '更新',
    useHiTA5AppToScan: '請使用HiTA5 APP掃碼',
    emailPhoneUserID: '醍摩豆帳號',
    password: '密碼',
    bindNow: '立刻綁定',
    sms: '手機簡訊',
    phoneNumber: '手機號碼',
    verificationCode: '驗證碼',
    confirmVerificationCode: '確認驗證碼',
    sendingSMS: '傳送簡訊中...',
    registerNewAccountPrompt: '請問是否要註冊全新醍摩豆帳號？',
    confirm: '確定',
    cancel: '取消',
    bindingSuccessful: '綁定成功',
    qrCodeLogin: 'QRCode登入',
    incorrectAccountOrPassword: '帳號或密碼錯誤',
    enterAccountAndPassword: '請輸入帳號及密碼',
    incorrectPhoneNumberFormat: '手機號碼格式錯誤',
    enterPhoneNumber: '請輸入手機號碼',
    incorrectAccountOrVerificationCode: '帳號或驗證碼錯誤',
    incorrectVerificationCodeFormat: '驗證碼格式錯誤',
    enterVerificationCode: '請輸入驗證碼',
    verificationCodeSendFailed: '驗證碼發送失敗',
    verificationCodeSent: '驗證碼已發送',
    afterBindingLoginWithEduCloud: '綁定後，下次即可直接使用教育雲帳號做登入囉！',
    loginWithEduCloud: '教育雲帳號登入',
    loginWithTeamModelAccount: '醍摩豆帳號登入',
    login: '登入'
  }
};
